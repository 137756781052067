import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const PlantingAuditUpdate = ({ newData, oldData }: IAuditLogEntry) => {
  const { fields, crops } = useSelector((state: RootState) => state.data);
  return (
    <>
      <Typography>
        Updated the Planting for "
        {fields[newData.fieldId]?.name || "Unknown Field"}" in {newData.year}
      </Typography>
      <ul style={{ margin: 0 }}>
        {Object.keys(newData).map((key) => {
          if (newData[key] === oldData[key]) return null;
          if (key === "modifiedOn") return null;
          if (key === "cropId") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the crop from "
                  {crops[oldData.cropId]?.name || "Unknown Crop"}" to "
                  {crops[newData.cropId]?.name || "Unknown Crop"}"
                </Typography>
              </li>
            );
          }
          return (
            <li key={key}>
              <Typography variant="body2">Changed the "{key}" value</Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default PlantingAuditUpdate;
