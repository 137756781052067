import { Grid, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteLocationAction } from "../../redux/dataActions";
import { colours } from "../../theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../ui";
import { ILocation } from "../../types";
import useHandleErrors from "../../useHandleErrors";

interface IDeleteLocationDialogProps {
  open: boolean;
  location: ILocation;
  onClose: () => void;
}

export const DeleteLocationDialog = ({
  open,
  location,
  onClose
}: IDeleteLocationDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (location?.id) {
      setDeleting(true);
      try {
        await dispatch(deleteLocationAction(location.id));
        enqueueSnackbar("Location deleted successfully", {
          variant: "success"
        });
        onClose();
        setDeleting(false);
      } catch (e: any) {
        setDeleting(false);
        handleErrors(e);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        title="Delete Location"
        onClose={onClose}
        loading={deleting}
      />

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Are you sure you want to delete this location?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {location.name && (
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "center" }}>
                    Location: {location.name}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <CustomButton variant="outlined" onClick={onClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="outlined"
          onClick={onDelete}
          startIcon={<Delete style={{ color: colours.red }} />}>
          Delete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLocationDialog;
