import { getAuth } from "firebase/auth";
import {
  QuerySnapshot,
  Unsubscribe,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  cropsCollection,
  fieldsCollection,
  locationsCollection,
  prolongedPlantingsCollection
} from "./firestore";
import { loadAccountAction } from "./redux/accountActions";
import { setFirebaseUserDetails } from "./redux/accountSlice";
import { loadMaxPlantingYear, loadMinPlantingYear } from "./redux/dataActions";
import {
  updateFields,
  deleteField,
  updateCrops,
  deleteCrop,
  updateLocations,
  deleteLocation,
  setFieldsLoading,
  setCropsLoading,
  setLocationsLoading,
  setProlongedPlantingsLoading,
  updateProlongedPlantings,
  deleteProlongedPlanting
} from "./redux/dataSlice";
import { RootState } from "./redux/store";
import { ICrop, IField, ILocation, IProlongedPlanting } from "./types";
import usePlantings from "./usePlantings";

export function processSnapshot<T>(snapshot: QuerySnapshot<T>) {
  const changes = snapshot.docChanges();
  const addedOrChanged: T[] = [];
  const removed: T[] = [];

  changes.forEach((change) => {
    const doc = change.doc;
    const data = doc.data();
    // @ts-ignore
    data.id = doc.id;
    switch (change.type) {
      case "added":
      case "modified":
        addedOrChanged.push(data);
        break;
      case "removed":
        removed.push(data);
        break;
      default:
        break;
    }
  });

  return { addedOrChanged, removed };
}

export const useFirebase = () => {
  const dispatch = useDispatch();
  const { mapPlantingYear } = useSelector((state: RootState) => state.ui);
  const { accountId, emailVerified } = useSelector(
    (state: RootState) => state.account
  );

  // Fetch the plantings for the selected year on the map
  usePlantings({ year: mapPlantingYear });

  useEffect(() => {
    const auth = getAuth();
    const unsubAuthChange = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setFirebaseUserDetails({
            email: user.email,
            emailVerified: user.emailVerified
          })
        );
        dispatch(loadAccountAction());
        dispatch(setFieldsLoading(true));
        dispatch(setCropsLoading(true));
        dispatch(setLocationsLoading(true));
      }
    });
    return () => unsubAuthChange();
  }, []);

  useEffect(() => {
    let unsubFieldsData: Unsubscribe;
    let unsubCropsData: Unsubscribe;
    let unsubLocationsData: Unsubscribe;
    let unsubProlongedPlantingsData: Unsubscribe;

    if (emailVerified && accountId) {
      dispatch(loadMaxPlantingYear({ setCurrentPlantingYear: true }));
      dispatch(loadMinPlantingYear());

      unsubFieldsData = onSnapshot(
        query(fieldsCollection, where("accountId", "==", accountId)),
        (snapshot) => {
          const { addedOrChanged, removed } = processSnapshot<IField>(snapshot);
          if (addedOrChanged.length) dispatch(updateFields(addedOrChanged));
          if (removed.length) {
            removed.forEach((item) => {
              dispatch(deleteField(item));
            });
          }
          dispatch(setFieldsLoading(false));
        }
      );

      unsubCropsData = onSnapshot(
        query(cropsCollection, where("accountId", "==", accountId)),
        (snapshot) => {
          const { addedOrChanged, removed } = processSnapshot<ICrop>(snapshot);
          if (addedOrChanged.length) dispatch(updateCrops(addedOrChanged));
          if (removed.length) {
            removed.forEach((item) => {
              dispatch(deleteCrop(item));
            });
          }
          dispatch(setCropsLoading(false));
        }
      );

      unsubLocationsData = onSnapshot(
        query(locationsCollection, where("accountId", "==", accountId)),
        (snapshot) => {
          const { addedOrChanged, removed } =
            processSnapshot<ILocation>(snapshot);
          if (addedOrChanged.length) dispatch(updateLocations(addedOrChanged));
          if (removed.length) {
            removed.forEach((item) => {
              dispatch(deleteLocation(item));
            });
          }
          dispatch(setLocationsLoading(false));
        }
      );

      unsubProlongedPlantingsData = onSnapshot(
        query(
          prolongedPlantingsCollection,
          where("accountId", "==", accountId)
        ),
        (snapshot) => {
          const { addedOrChanged, removed } =
            processSnapshot<IProlongedPlanting>(snapshot);
          if (addedOrChanged.length)
            dispatch(updateProlongedPlantings(addedOrChanged));
          if (removed.length) {
            removed.forEach((item) => {
              dispatch(deleteProlongedPlanting(item));
            });
          }
          dispatch(setProlongedPlantingsLoading(false));
        }
      );
    }

    return () => {
      unsubFieldsData?.();
      unsubCropsData?.();
      unsubLocationsData?.();
      unsubProlongedPlantingsData?.();
    };
  }, [accountId, emailVerified]);
};

export default useFirebase;
