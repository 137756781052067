import { authenticatedAxios } from "../myAxios";
import {
  setMaxPlantingYear,
  setMinPlantingYear,
  deleteCrop,
  deleteField,
  deleteLocation,
  deletePlanting,
  updateCrops,
  updateFields,
  updateLocations,
  updatePlantings,
  updateProlongedPlantings,
  deleteProlongedPlanting
} from "./dataSlice";
import { AppDispatch } from "./store";
import { setMapPlantingYear } from "./uiSlice";

interface ICreateFieldData {
  name: string;
  size: string;
  feature: string;
}

interface IEditFieldData {
  size?: string;
  name?: string;
  geometry?: any; // TODO: type
}

interface ICreateCropData {
  name: string;
  colour: string;
}

interface IEditCropData {
  name: string;
  colour: string;
}

interface ICreatePlantingData {
  fieldId: string;
  cropId: string;
  year: string;
}

interface IEditPlantingData {
  cropId: string;
  year?: string;
}

interface ICreateProlongedPlantingData {
  fieldId: string;
  cropId: string;
  startYear?: string;
  endYear?: string;
}

interface ICreateLocationData {
  name: string;
  long: string;
  lat: string;
  zoom: string;
}

interface IEditLocationData {
  name?: string;
  long?: string;
  lat?: string;
  zoom?: string;
}

export const loadMinPlantingYear = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .get("/min/plantingYear")
      .then((resp) => {
        dispatch(setMinPlantingYear(resp.data));
        return resolve(resp);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      })
  );

export const loadMaxPlantingYear =
  (options?: { setCurrentPlantingYear?: boolean }) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .get("/max/plantingYear")
        .then((resp) => {
          dispatch(setMaxPlantingYear(resp.data));
          if (options?.setCurrentPlantingYear) {
            dispatch(setMapPlantingYear(resp.data));
          }
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const createFieldAction =
  (formData: ICreateFieldData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .post("/fields", formData)
        .then((resp) => {
          dispatch(updateFields([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const editFieldAction =
  (id: string, formData: IEditFieldData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .put(`/fields/${id}`, formData)
        .then((resp) => {
          dispatch(updateFields([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const deleteFieldAction = (id: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .delete(`/fields/${id}`)
      .then((resp) => {
        dispatch(deleteField(resp.data));
        return resolve(resp);
      })
      .catch((err) => {
        return reject(err);
      })
  );

export const createCropAction =
  (formData: ICreateCropData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .post("/crops", formData)
        .then((resp) => {
          dispatch(updateCrops([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          return reject(err);
        })
    );

export const editCropAction =
  (id: string, formData: IEditCropData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .put(`/crops/${id}`, formData)
        .then((resp) => {
          dispatch(updateCrops([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          return reject(err);
        })
    );

export const deleteCropAction = (id: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .delete(`/crops/${id}`)
      .then((resp) => {
        dispatch(deleteCrop(resp.data));
        return resolve(resp);
      })
      .catch((err) => {
        return reject(err);
      })
  );

export const createPlantingAction =
  (formData: ICreatePlantingData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .post("/plantings", formData)
        .then((resp) => {
          dispatch(updatePlantings([resp.data]));
          // @ts-ignore
          dispatch(loadMaxPlantingYear());
          // @ts-ignore
          dispatch(loadMinPlantingYear());
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const editPlantingAction =
  (id: string, formData: IEditPlantingData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .put(`/plantings/${id}`, formData)
        .then((resp) => {
          dispatch(updatePlantings([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const deletePlantingAction = (id: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .delete(`/plantings/${id}`)
      .then((resp) => {
        dispatch(deletePlanting(resp.data));
        return resolve(resp);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      })
  );

export const createProlongedPlantingAction =
  (formData: ICreateProlongedPlantingData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .post("/prolongedPlantings", formData)
        .then((resp) => {
          dispatch(updateProlongedPlantings([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const deleteProlongedPlantingAction =
  (id: string) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .delete(`/prolongedPlantings/${id}`)
        .then((resp) => {
          dispatch(deleteProlongedPlanting(resp.data));
          return resolve(resp);
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        })
    );

export const createLocationAction =
  (formData: ICreateLocationData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .post("/locations", formData)
        .then((resp) => {
          dispatch(updateLocations([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          return reject(err);
        })
    );

export const editLocationAction =
  (id: string, formData: IEditLocationData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .put(`/locations/${id}`, formData)
        .then((resp) => {
          dispatch(updateLocations([resp.data]));
          return resolve(resp);
        })
        .catch((err) => {
          return reject(err);
        })
    );

export const deleteLocationAction = (id: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .delete(`/locations/${id}`)
      .then((resp) => {
        dispatch(deleteLocation(resp.data));
        return resolve(resp);
      })
      .catch((err) => {
        return reject(err);
      })
  );
