import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeArrayOfNumbers } from "../../helpers";
import { RootState } from "../../redux/store";
import { CustomButton } from "../../ui";

const useStyles = makeStyles({
  selectRoot: { whiteSpace: "normal" }
});

interface IPlantingFiltersProps {
  plantingYear: string;
  fieldId: string;
  onChangePlantingYear: (year: string) => void;
  onChangeFieldId: (id: string) => void;
}

const PlantingFilters = ({
  plantingYear,
  fieldId,
  onChangePlantingYear,
  onChangeFieldId
}: IPlantingFiltersProps) => {
  const { minPlantingYear, maxPlantingYear, fieldsLoading, fields } =
    useSelector((state: RootState) => state.data);
  const classes = useStyles();
  const fieldValues = useMemo(
    () => Object.values(fields).sort((a, b) => a.name.localeCompare(b.name)),
    [fields]
  );
  const disabledYearSelect = !minPlantingYear || !maxPlantingYear;
  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item xs>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={disabledYearSelect}>
              <InputLabel id="yearSelectLabel">Year</InputLabel>
              <Select
                label="Year"
                labelId="yearSelectLabel"
                disabled={disabledYearSelect}
                value={plantingYear}
                onChange={(e) =>
                  onChangePlantingYear(e.target.value as string)
                }>
                {!disabledYearSelect &&
                  makeArrayOfNumbers(+minPlantingYear, +maxPlantingYear).map(
                    (year) => (
                      <MenuItem key={year} value={"" + year}>
                        {year}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <CustomButton
              tooltipText="Clear Year"
              color="primary"
              onClick={() => onChangePlantingYear("")}
              disabled={plantingYear === ""}
              style={{ marginLeft: 10 }}>
              <HighlightOff style={{ color: "#ffffff" }} />
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item xs>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={!fieldValues.length}>
              <InputLabel id="fieldSelectLabel">Field</InputLabel>
              <Select
                label="Field"
                labelId="fieldSelectLabel"
                value={fieldId}
                onChange={(e) => onChangeFieldId(e.target.value as string)}
                classes={{ root: classes.selectRoot }}>
                {!fieldsLoading &&
                  fieldValues.map((field) => (
                    <MenuItem key={field.id} value={field.id}>
                      {field.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <CustomButton
              tooltipText="Clear Field"
              color="primary"
              onClick={() => onChangeFieldId("")}
              disabled={fieldId === ""}
              style={{ marginLeft: 10 }}>
              <HighlightOff style={{ color: "#ffffff" }} />
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlantingFilters;
