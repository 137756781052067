import { createSlice } from "@reduxjs/toolkit";

import {
  IField,
  IPlanting,
  ICrop,
  ILocation,
  IProlongedPlanting
} from "../types";

export type TFields = { [key: string]: IField };
export type TPlantings = { [key: string]: IPlanting };
export type TCrops = { [key: string]: ICrop };
export type TLocations = { [key: string]: ILocation };
export type TProlongedPlantings = { [key: string]: IProlongedPlanting };

interface DataState {
  minPlantingYear: string;
  maxPlantingYear: string;
  fields: TFields;
  plantings: TPlantings;
  crops: TCrops;
  locations: TLocations;
  fieldsLoading: boolean;
  plantingsLoading: boolean;
  cropsLoading: boolean;
  locationsLoading: boolean;
  prolongedPlantings: TProlongedPlantings;
  prolongedPlantingsLoading: boolean;
}

export const initialDataState: DataState = {
  minPlantingYear: "",
  maxPlantingYear: "",
  // Fields
  fields: {},
  fieldsLoading: false,
  // Crops
  crops: {},
  cropsLoading: false,
  // Plantings
  plantings: {},
  plantingsLoading: false,
  // Locations
  locations: {},
  locationsLoading: false,
  // Prolonged Plantings
  prolongedPlantings: {},
  prolongedPlantingsLoading: false
};

const dataSlice = createSlice({
  name: "data",
  initialState: initialDataState,
  reducers: {
    setMinPlantingYear: (state, { payload }) => {
      state.minPlantingYear = payload;
    },
    setMaxPlantingYear: (state, { payload }) => {
      state.maxPlantingYear = payload;
    },
    // Fields
    setFieldsLoading: (state, { payload }) => {
      state.fieldsLoading = payload;
    },
    updateFields: (state, { payload }) => {
      payload.forEach((field: IField) => {
        if (field.id) state.fields[field.id] = field;
      });
      state.fieldsLoading = false;
    },
    deleteField: (state, { payload }) => {
      delete state.fields[payload.id];
    },
    // Crops
    setCropsLoading: (state, { payload }) => {
      state.cropsLoading = payload;
    },
    updateCrops: (state, { payload }) => {
      payload.forEach((crop: ICrop) => {
        if (crop.id) state.crops[crop.id] = crop;
      });
      state.cropsLoading = false;
    },
    deleteCrop: (state, { payload }) => {
      delete state.crops[payload.id];
    },
    // Plantings
    setPlantingsLoading: (state, { payload }) => {
      state.plantingsLoading = payload;
    },
    updatePlantings: (state, { payload }) => {
      payload.forEach((planting: IPlanting) => {
        if (planting.id) state.plantings[planting.id] = planting;
      });
    },
    deletePlanting: (state, { payload }) => {
      delete state.plantings[payload.id];
    },
    // Locations
    setLocationsLoading: (state, { payload }) => {
      state.locationsLoading = payload;
    },
    updateLocations: (state, { payload }) => {
      payload.forEach((location: ILocation) => {
        if (location.id) state.locations[location.id] = location;
      });
      state.locationsLoading = false;
    },
    deleteLocation: (state, { payload }) => {
      delete state.locations[payload.id];
    },
    // Prolonged Plantings
    setProlongedPlantingsLoading: (state, { payload }) => {
      state.prolongedPlantingsLoading = payload;
    },
    updateProlongedPlantings: (state, { payload }) => {
      payload.forEach((planting: IProlongedPlanting) => {
        if (planting.id) state.prolongedPlantings[planting.id] = planting;
      });
    },
    deleteProlongedPlanting: (state, { payload }) => {
      delete state.prolongedPlantings[payload.id];
    }
  }
});

export const {
  setMinPlantingYear,
  setMaxPlantingYear,
  // Fields
  setFieldsLoading,
  updateFields,
  deleteField,
  // Crops
  setCropsLoading,
  updateCrops,
  deleteCrop,
  // Plantings
  setPlantingsLoading,
  updatePlantings,
  deletePlanting,
  // Locations
  setLocationsLoading,
  updateLocations,
  deleteLocation,
  // Prolonged Plantings
  setProlongedPlantingsLoading,
  updateProlongedPlantings,
  deleteProlongedPlanting
} = dataSlice.actions;

export default dataSlice.reducer;
