import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Edit } from "@material-ui/icons";

import { IPlantingListItem } from "../../types";
import { convertSquareMetersToAcres } from "../../helpers";
import { RootState } from "../../redux/store";

interface IMobilePlantingsProps {
  plantingValues: IPlantingListItem[];
  onEditPlanting: (id: string) => void;
}

const MobilePlantings = ({
  plantingValues,
  onEditPlanting
}: IMobilePlantingsProps) => {
  const theme = useTheme();
  const { fields, crops } = useSelector((state: RootState) => state.data);
  return (
    <Grid container spacing={2}>
      {plantingValues.map((planting) => {
        const field = fields[planting.fieldId];
        const crop = crops[planting.cropId];
        return (
          <Grid
            item
            xs={12}
            key={
              planting.plantingId ||
              `${planting.year}:${planting.prolongedPlantingId}`
            }>
            <div
              style={{
                borderRadius: 10,
                background: theme.palette.background.default,
                border: `1px solid ${theme.palette.divider}`,
                display: "flex"
              }}>
              <div
                data-testid={`crop-colour-bar-${crop.id}`}
                style={{
                  backgroundColor: crop.colour,
                  width: 20,
                  borderRadius: "9px 0px 0px 9px"
                }}
              />
              <div style={{ padding: 10, width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography>{field?.name || ""}</Typography>
                    <Grid container>
                      <Typography variant="body2">
                        {`${crop?.name || ""} | ${
                          convertSquareMetersToAcres(field?.size) || ""
                        } acres | ${planting.year}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {planting.plantingId && (
                    <Grid item>
                      <IconButton
                        onClick={() => onEditPlanting(planting.plantingId!)}>
                        <Tooltip
                          placement="top"
                          title={
                            <Typography variant="body2">
                              Edit Planting
                            </Typography>
                          }>
                          <Edit />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MobilePlantings;
