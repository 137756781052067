import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const PlantingAuditCreate = ({ newData }: IAuditLogEntry) => {
  const { fields, crops } = useSelector((state: RootState) => state.data);
  return (
    <>
      <Typography>Created a new Planting</Typography>
      <ul style={{ margin: 0 }}>
        <li>Field: {fields[newData.fieldId]?.name || "Unknown Field"}</li>
        <li>Crop: {crops[newData.cropId]?.name || "Unknown Crop"}</li>
        <li>Year: {newData.year}</li>
      </ul>
    </>
  );
};

export default PlantingAuditCreate;
