import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { convertSquareMetersToAcres } from "../../../../helpers";

export const FieldAuditCreate = ({ newData }: IAuditLogEntry) => {
  return (
    <>
      <Typography>Created a new Field "{newData.name}"</Typography>
      <ul style={{ margin: 0 }}>
        <li>Size: {convertSquareMetersToAcres(newData.size)}ac</li>
      </ul>
    </>
  );
};

export default FieldAuditCreate;
