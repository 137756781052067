import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { RootState } from "../redux/store";

export const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const { authenticated, emailVerified } = useSelector(
    (state: RootState) => state.account
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Redirect to="/login" />
        ) : emailVerified === false &&
          props.location.pathname !== "/verifyEmail" ? (
          <Redirect to="/verifyEmail" />
        ) : (
          // @ts-ignore
          <Component {...props} />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
