import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TextField,
  Tooltip
} from "@material-ui/core";
import { InfoOutlined, Clear, Add } from "@material-ui/icons";
import { useState } from "react";

import { TCrops } from "../../redux/dataSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CustomButton
} from "../../ui";
import Loading from "../Loading";
import Crop from "./Crop";
import CropDialog from "./CropDialog";

const useStyles = makeStyles({
  noCropsContainer: {
    width: "100%",
    maxWidth: 800,
    margin: "auto"
  },
  searchContainer: {
    paddingBottom: 20,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 250
  }
});

const addCropText = "Add Crop";

const InfoContent = () => (
  <Grid
    container
    spacing={1}
    justifyContent="center"
    style={{ textAlign: "center" }}>
    <Grid item>
      <Typography>
        This is where your crops are displayed, each with a configurable colour.
      </Typography>
    </Grid>
    <Grid item>
      <Typography>To add crops, click the "{addCropText}" button.</Typography>
    </Grid>
  </Grid>
);

interface IInfoDialogProps {
  open: boolean;
  onClose: Function;
}

const InfoDialog = ({ open, onClose }: IInfoDialogProps) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle title="Info" onClose={() => onClose()} />
      <DialogContent>
        <InfoContent />
      </DialogContent>
    </Dialog>
  );
};

const NoCrops = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.noCropsContainer}
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}>
      <Grid item>
        <InfoContent />
      </Grid>
    </Grid>
  );
};

interface ICropsProps {
  open: boolean;
  crops: TCrops;
  loading: boolean;
  onClose: () => void;
}

export const Crops = ({ open, crops, loading, onClose }: ICropsProps) => {
  const classes = useStyles();

  const [createEditDialogOpen, setCreateEditDialogOpen] = useState(false);
  const [editCropId, setEditCropId] = useState("");
  const [cropNameSearch, setCropNameSearch] = useState("");
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const cropValues = Object.values(crops) || [];
  const filteredCropValues = cropValues.filter((crop) =>
    cropNameSearch.length > 0
      ? crop.name.toLowerCase().includes(cropNameSearch)
      : true
  );

  const onEdit = (cropId: string) => {
    setCreateEditDialogOpen(true);
    setEditCropId(cropId);
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    } else {
      if (cropValues.length === 0) return <NoCrops />;
      return (
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item className={classes.searchContainer}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={cropNameSearch}
                  onChange={(e) =>
                    setCropNameSearch(e.target.value.toLowerCase())
                  }
                  InputProps={{
                    endAdornment: cropNameSearch && (
                      <IconButton
                        size="small"
                        onClick={() => setCropNameSearch("")}>
                        <Clear />
                      </IconButton>
                    )
                  }}
                  data-testid="crops-search"
                />
              </Grid>
              <Grid item>
                <Tooltip
                  title={<Typography variant="body2">Info</Typography>}
                  placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => setInfoDialogOpen(true)}
                    data-testid="crops-info-btn">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {filteredCropValues.length ? (
                filteredCropValues.map((crop) => (
                  <Grid item key={crop.id} xs={12} sm={6} md={4} lg={3}>
                    <Crop crop={crop} onEdit={onEdit} />
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography data-testid="no-matching-crops-text">
                    No matching crops found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Dialog open={open} fullScreen onClose={onClose}>
        <DialogTitle title="Crops" onClose={onClose} />
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>
          <CustomButton
            color="primary"
            onClick={() => setCreateEditDialogOpen(true)}
            disabled={loading}
            startIcon={<Add />}
            data-testid="add-crop-btn">
            {addCropText}
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CropDialog
        open={createEditDialogOpen}
        cropId={editCropId}
        onClose={() => {
          setCreateEditDialogOpen(false);
          setEditCropId("");
        }}
      />
      <InfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
      />
    </>
  );
};

export default Crops;
