import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import yearsArray from "../yearsArray";
import { ISharedLayoutProps } from "./shared";

export const SingleYearLayout = ({
  fieldValues,
  cropValues
}: ISharedLayoutProps) => {
  const { control } = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          Single year plantings are for when a field is allocated a crop for
          only one year at a time. These take priority over multi-year
          plantings.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="fieldId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={!fieldValues.length}
              error={!!error}>
              <InputLabel id="fieldSelectLabel">Field*</InputLabel>
              <Select
                label="Field*"
                labelId="fieldSelectLabel"
                inputProps={{
                  "data-testid": "new-planting-dialog-field-select"
                }}
                {...field}>
                {fieldValues.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="cropId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={!cropValues.length}
              error={!!error}>
              <InputLabel id="cropSelectLabel">Crop*</InputLabel>
              <Select
                label="Crop*"
                labelId="cropSelectLabel"
                inputProps={{
                  "data-testid": "new-planting-dialog-crop-select"
                }}
                {...field}>
                {cropValues.map((crop) => (
                  <MenuItem
                    key={crop.id}
                    value={crop.id}
                    style={{ borderLeft: `10px solid ${crop.colour}` }}>
                    {crop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="year"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={!!error}>
              <InputLabel id="yearSelectLabel">Year*</InputLabel>
              <Select
                label="Year*"
                labelId="yearSelectLabel"
                inputProps={{
                  "data-testid": "new-planting-dialog-year-select"
                }}
                {...field}>
                {yearsArray.map((year) => (
                  <MenuItem key={year} value={`${year}`}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SingleYearLayout;
