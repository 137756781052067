import {
  CircularProgress,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { CustomButton, Dialog, DialogContent, DialogTitle } from "../../ui";
import useAuditLog, {
  IUseAuditLogFilters,
  resultsLimit
} from "../../useAuditLog";
import { IAuditLogEntry, TAuditEntityType, TAuditEventType } from "../../types";
import { drawerWidth } from "../../theme";
import { useMemo, useState } from "react";
import { HighlightOff } from "@material-ui/icons";

export const entityTitleMap: { [key in TAuditEntityType]: string } = {
  crop: "Crop",
  field: "Field",
  planting: "Planting",
  prolongedPlanting: "Prolonged Planting",
  location: "Location",
  account: "Account"
};

interface IAuditLogProps {
  open: boolean;
  onClose: () => void;
}

const EventDescription = (log: IAuditLogEntry) => {
  switch (log.eventType) {
    case "UPDATE":
      try {
        const Component = require(
          `./EventDescriptions/Update/${log.entityType}`
        )?.default;
        return <Component {...log} />;
      } catch (err) {
        return <Typography>Updated a {log.entityType}</Typography>;
      }
    case "CREATE":
      try {
        const Component = require(
          `./EventDescriptions/Create/${log.entityType}`
        )?.default;
        return <Component {...log} />;
      } catch (err) {
        return <Typography>Created a {log.entityType}</Typography>;
      }
    case "DELETE":
      try {
        const Component = require(
          `./EventDescriptions/Delete/${log.entityType}`
        )?.default;
        return <Component {...log} />;
      } catch (err) {
        return <Typography>Deleted a {log.entityType}</Typography>;
      }
    default:
      return null;
  }
};

export const AuditLog = ({ open, onClose }: IAuditLogProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [entityTypeFilter, setEntityTypeFilter] = useState<
    TAuditEntityType | ""
  >("");
  const [eventTypeFilter, setEventTypeFilter] = useState<TAuditEventType | "">(
    ""
  );

  const filters = useMemo(() => {
    const ret: IUseAuditLogFilters = {};
    if (entityTypeFilter) ret["entityType"] = entityTypeFilter;
    if (eventTypeFilter) ret["eventType"] = eventTypeFilter;
    return ret;
  }, [entityTypeFilter, eventTypeFilter]);

  const { logs, loading } = useAuditLog({
    skip: !open,
    filters
  });

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      style={{ right: mobile ? 0 : drawerWidth }}>
      <DialogTitle
        title="Audit Log"
        onClose={onClose}
        showMenuIcon={mobile}
        onMenuOpen={() => setDrawerOpen(true)}
      />
      <DialogContent>
        <Typography variant="body2" style={{ marginBottom: 20 }}>
          This page displays a list of changes that you have made to your data,
          limited to {resultsLimit} rows.
        </Typography>
        <Drawer
          variant={mobile ? "temporary" : "permanent"}
          anchor="right"
          open={mobile ? drawerOpen : true}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              width: drawerWidth,
              overflow: "hidden"
            }
          }}>
          <Grid container spacing={3} style={{ padding: 20 }}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item xs>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="entityTypeSelectLabel">
                          Entity Type
                        </InputLabel>
                        <Select
                          label="Entity Type"
                          labelId="entityTypeSelectLabel"
                          value={entityTypeFilter}
                          onChange={(e) =>
                            setEntityTypeFilter(
                              e.target.value as TAuditEntityType
                            )
                          }>
                          <MenuItem key="field" value="field">
                            Field
                          </MenuItem>
                          <MenuItem key="crop" value="crop">
                            Crop
                          </MenuItem>
                          <MenuItem key="planting" value="planting">
                            Planting
                          </MenuItem>
                          <MenuItem
                            key="prolongedPlanting"
                            value="prolongedPlanting">
                            Prolonged Planting
                          </MenuItem>
                          <MenuItem key="location" value="location">
                            Location
                          </MenuItem>
                          <MenuItem key="account" value="account">
                            Account
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        tooltipText="Clear Filter"
                        color="primary"
                        onClick={() => setEntityTypeFilter("")}
                        disabled={!entityTypeFilter}
                        style={{ marginLeft: 10 }}>
                        <HighlightOff style={{ color: "#ffffff" }} />
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item xs>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="eventTypeSelectLabel">
                          Event Type
                        </InputLabel>
                        <Select
                          label="Event Type"
                          labelId="eventTypeSelectLabel"
                          value={eventTypeFilter}
                          onChange={(e) =>
                            setEventTypeFilter(
                              e.target.value as TAuditEventType
                            )
                          }>
                          <MenuItem key="create" value="CREATE">
                            Create
                          </MenuItem>
                          <MenuItem key="update" value="UPDATE">
                            Update
                          </MenuItem>
                          <MenuItem key="delete" value="DELETE">
                            Delete
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        tooltipText="Clear Filter"
                        color="primary"
                        onClick={() => setEventTypeFilter("")}
                        disabled={!eventTypeFilter}
                        style={{ marginLeft: 10 }}>
                        <HighlightOff style={{ color: "#ffffff" }} />
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
        {logs.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableCell>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Time
                </Typography>
              </TableCell>
              {!mobile && (
                <TableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Entity Type
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Description
                </Typography>
              </TableCell>
            </TableHead>
            <TableBody>
              {logs.map((log) => {
                const updatedAtDate = new Date(log.updatedAt);
                return (
                  <TableRow key={`${log.documentId}-${log.updatedAt}`}>
                    <TableCell style={{ width: mobile ? 100 : 200 }}>
                      <Typography>{updatedAtDate.toLocaleString()}</Typography>
                    </TableCell>
                    {!mobile && (
                      <TableCell style={{ width: 150 }}>
                        <Typography>
                          {entityTitleMap[log.entityType]}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <EventDescription {...log} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: 400 }}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body2">
            You have no audit log entries to show at the moment. After you have
            performed some actions (added a new planting etc.) you will start to
            see them appear.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AuditLog;
