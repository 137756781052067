import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const ProlongedPlantingAuditUpdate = ({
  newData,
  oldData
}: IAuditLogEntry) => {
  const { fields, crops } = useSelector((state: RootState) => state.data);
  return (
    <>
      <Typography>
        Updated a Prolonged Planting for "
        {fields[newData.fieldId]?.name || "Unknown Field"}"
      </Typography>
      <ul style={{ margin: 0 }}>
        {Object.keys(newData).map((key) => {
          if (newData[key] === oldData[key]) return null;
          if (key === "modifiedOn") return null;
          if (key === "startYear" || key === "endYear") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the {key === "startYear" ? "Start Year" : "End Year"}{" "}
                  from {oldData[key] || "Unset"} to {newData[key] || "Unset"}
                </Typography>
              </li>
            );
          }
          if (key === "cropId") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the crop from "
                  {crops[oldData.cropId]?.name || "Unknown Crop"}" to "
                  {crops[newData.cropId]?.name || "Unknown Crop"}"
                </Typography>
              </li>
            );
          }
          return (
            <li key={key}>
              <Typography variant="body2">Changed the "{key}" value</Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ProlongedPlantingAuditUpdate;
