import { createSlice } from "@reduxjs/toolkit";

import { IAccount } from "../types";

interface IAccountState extends IAccount {
  authenticated: boolean;
  emailVerified: boolean;
}

export const initialAccountState: IAccountState = {
  authenticated: false,
  accountId: "",
  accountName: "",
  defaultMapLocation: "",
  defaultMapStyle: "monochrome",
  email: "",
  createdOn: "",
  modifiedOn: "",
  emailVerified: false
};

const accountSlice = createSlice({
  name: "account",
  initialState: initialAccountState,
  reducers: {
    setAccount: (state, { payload }) => {
      state.accountId = payload.accountId;
      state.accountName = payload.accountName;
      state.defaultMapLocation = payload.defaultMapLocation;
      state.defaultMapStyle = payload.defaultMapStyle;
      state.createdOn = payload.createdOn;
      state.modifiedOn = payload.modifiedOn;
      state.authenticated = true;
    },
    setFirebaseUserDetails: (state, { payload }) => {
      state.email = payload.email;
      state.emailVerified = payload.emailVerified;
    },
    clearAccount: (state) => {
      state = initialAccountState;
    }
  }
});

export const { setAccount, clearAccount, setFirebaseUserDetails } =
  accountSlice.actions;

export default accountSlice.reducer;
