import axios from "axios";

import { getFirebaseIdToken } from "./firebaseAuth";
import { getToken } from "./token";

export const baseAxios = axios.create();
baseAxios.defaults.baseURL = process.env.REACT_APP_FB_API_ENDPOINT;

export const authenticatedAxios = axios.create();
authenticatedAxios.defaults.baseURL = process.env.REACT_APP_FB_API_ENDPOINT;

// Executes before every request
authenticatedAxios.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${getToken()}`;
  return config;
});

// Executes for every response
authenticatedAxios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Get new token then retry the request
      await getFirebaseIdToken();
      return authenticatedAxios(originalRequest);
    }

    return Promise.reject(err);
  }
);
