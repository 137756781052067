import { TextField, Typography, Grid } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { useDispatch, useSelector } from "react-redux";

import {
  createCropAction,
  deleteCropAction,
  editCropAction
} from "../../redux/dataActions";
import { RootState } from "../../redux/store";
import { colours } from "../../theme";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CustomButton
} from "../../ui";
import useHandleErrors from "../../useHandleErrors";

interface ICropDialogProps {
  open: boolean;
  onClose: () => void;
  cropId?: string;
}

interface IDeleteCropDialogProps {
  open: boolean;
  cropId: string;
  onClose: () => void;
  onCropDeleted: () => void;
}

const DeleteCropDialog = ({
  open,
  cropId,
  onClose,
  onCropDeleted
}: IDeleteCropDialogProps) => {
  const { crops } = useSelector((state: RootState) => state.data);
  const crop = cropId ? crops[cropId] : undefined;
  const dispatch = useDispatch();
  const { handleErrors } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();

  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    setDeleting(true);
    if (crop?.id) {
      try {
        await dispatch(deleteCropAction(crop.id));
        enqueueSnackbar("Crop deleted successfully", {
          variant: "success"
        });
        onClose();
        onCropDeleted();
        setDeleting(false);
      } catch (e: any) {
        setDeleting(false);
        handleErrors(e);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      data-testid="delete-crop-dialog">
      <DialogTitle
        title="Delete Crop"
        onClose={() => onClose()}
        loading={deleting}
      />

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Are you sure you want to delete this crop?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {crop?.name && (
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "center" }}>
                    Crop: {crop.name}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <CustomButton variant="outlined" onClick={() => onClose()}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="outlined"
          onClick={onDelete}
          startIcon={<Delete style={{ color: colours.red }} />}
          disabled={deleting}>
          Delete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export const CropDialog = ({ open, onClose, cropId }: ICropDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const { crops } = useSelector((state: RootState) => state.data);
  const crop = cropId ? crops[cropId] : undefined;

  const [name, setName] = useState("");
  const [colour, setColour] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Update values if crop changes
  useEffect(() => {
    setName(crop?.name || "");
    setColour(crop?.colour || "");
  }, [crop]);

  const getCreateUpdateDisabled = () => {
    if (saving) return true;
    if (crop)
      return !colour || !name || (name === crop.name && colour === crop.colour);
    else return !name || !colour;
  };

  const onSubmit = async () => {
    setSaving(true);
    if (crop?.id) {
      try {
        await dispatch(editCropAction(crop.id, { name, colour }));
        enqueueSnackbar("Crop updated successfully", { variant: "success" });
        setSaving(false);
        close();
      } catch (e: any) {
        setSaving(false);
        handleErrors(e);
      }
    } else {
      try {
        await dispatch(createCropAction({ name, colour }));
        enqueueSnackbar("Crop created successfully", { variant: "success" });
        setSaving(false);
        close();
      } catch (e: any) {
        setSaving(false);
        handleErrors(e);
      }
    }
  };

  const close = () => {
    onClose();
    setName("");
    setColour("");
  };

  return (
    <>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle
          title={`${crop ? "Edit" : "New"} Crop`}
          onClose={close}
          loading={saving}
          id="crop-dialog-title"
        />
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="body2" style={{ textAlign: "center" }}>
                Enter a name and pick a colour for this crop.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                autoFocus
                label="Name"
                variant="outlined"
                size="small"
                value={name}
                inputProps={{ "data-testid": "crop-dialog-name-input" }}
                onChange={(e) => {
                  if (e.target.value.length <= 40) setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <HexColorPicker
                color={colour}
                onChange={setColour}
                data-testid="crop-dialog-color-picker"
                data-color={colour}
                style={{ width: "100%", marginTop: 20 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {crop && (
            <CustomButton
              variant="outlined"
              onClick={() => setDeleteConfirmationOpen(true)}
              startIcon={<Delete style={{ color: colours.red }} />}
              data-testid="crop-dialog-delete-btn">
              Delete
            </CustomButton>
          )}
          <Grid item xs />
          <CustomButton
            variant="outlined"
            onClick={close}
            data-testid="crop-dialog-cancel-btn">
            Cancel
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => onSubmit()}
            disabled={getCreateUpdateDisabled()}
            data-testid="crop-dialog-save-btn">
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>

      {/* Dialog to confirm whether the user really wants to delete the crop */}
      {cropId && (
        <DeleteCropDialog
          open={deleteConfirmationOpen}
          cropId={cropId}
          onClose={() => setDeleteConfirmationOpen(false)}
          onCropDeleted={onClose}
        />
      )}
    </>
  );
};

export default CropDialog;
