import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TextField,
  Tooltip,
  useTheme
} from "@material-ui/core";
import { BorderColor, InfoOutlined, Clear } from "@material-ui/icons";
import { useState } from "react";

import { TFields } from "../../redux/dataSlice";
import { Dialog, DialogContent, DialogTitle } from "../../ui";
import Loading from "../Loading";
import Field from "./Field";
import FieldDialog from "./FieldDialog";

const useStyles = makeStyles({
  noFieldsContainer: { width: "100%", maxWidth: 800, margin: "auto" },
  fieldContainer: {
    borderRadius: 10,
    background: "#f3f2f1"
  },
  fieldNameContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 20px",
    overflow: "hidden",
    wordBreak: "break-word"
  },
  searchContainer: {
    paddingBottom: 20,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 250
  },
  fieldCanvasContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 10
  }
});

const InfoContent = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      style={{ textAlign: "center" }}>
      <Grid item>
        <Typography>This is where your fields are displayed.</Typography>
      </Grid>
      <Grid item>
        <Typography>
          To add fields, click the{" "}
          <span style={{ paddingLeft: 5, paddingRight: 10 }}>
            <BorderColor style={{ color: theme.palette.text.primary }} />
          </span>
          button on the map then select the boundaries of the field.
        </Typography>
      </Grid>
    </Grid>
  );
};

interface IInfoDialogProps {
  open: boolean;
  onClose: Function;
}

const InfoDialog = ({ open, onClose }: IInfoDialogProps) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle title="Info" onClose={() => onClose()} />
      <DialogContent>
        <InfoContent />
      </DialogContent>
    </Dialog>
  );
};

const NoFields = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      className={classes.noFieldsContainer}>
      <Grid item>
        <InfoContent />
      </Grid>
    </Grid>
  );
};

interface IFieldsProps {
  open: boolean;
  loading: boolean;
  fields: TFields;
  onEditShape: (fieldId: string) => void;
  onClose: () => void;
}

export const Fields = ({
  open,
  loading,
  fields,
  onEditShape,
  onClose
}: IFieldsProps) => {
  const classes = useStyles();

  const [createEditDialogOpen, setCreateEditDialogOpen] = useState(false);
  const [editFieldId, setEditFieldId] = useState("");
  const [fieldNameSearch, setFieldNameSearch] = useState("");
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const fieldValues = Object.values(fields) || [];
  const filteredFieldValues = fieldValues
    .sort((a, b) => (+a.size > +b.size ? -1 : 1))
    .filter((field) =>
      fieldNameSearch.length > 0
        ? field.name.toLowerCase().includes(fieldNameSearch)
        : true
    );

  const onEditName = (fieldId: string) => {
    setCreateEditDialogOpen(true);
    setEditFieldId(fieldId);
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    } else {
      if (fieldValues.length === 0) return <NoFields />;
      return (
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item className={classes.searchContainer}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={fieldNameSearch}
                  onChange={(e) =>
                    setFieldNameSearch(e.target.value.toLowerCase())
                  }
                  InputProps={{
                    endAdornment: fieldNameSearch && (
                      <IconButton
                        size="small"
                        onClick={() => setFieldNameSearch("")}>
                        <Clear />
                      </IconButton>
                    )
                  }}
                  data-testid="fields-search"
                />
              </Grid>
              <Grid item>
                <Tooltip
                  title={<Typography variant="body2">Info</Typography>}
                  placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => setInfoDialogOpen(true)}
                    data-testid="fields-info-btn">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              {filteredFieldValues.length ? (
                filteredFieldValues.map((field) => (
                  <Grid item key={field.id} xs={12} sm={6} md={4} lg={3}>
                    <Field
                      field={field}
                      onEditName={onEditName}
                      onEditShape={(fieldId) => {
                        onEditShape(fieldId);
                        onClose();
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography data-testid="no-matching-fields-text">
                    No matching fields found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Dialog open={open} fullScreen onClose={onClose}>
        <DialogTitle title="Fields" onClose={onClose} />
        <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
      <FieldDialog
        open={createEditDialogOpen}
        field={fields[editFieldId]}
        onClose={() => {
          setCreateEditDialogOpen(false);
          setEditFieldId("");
        }}
      />
      <InfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
      />
    </>
  );
};

export default Fields;
