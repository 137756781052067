import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getIdToken,
  sendPasswordResetEmail
} from "firebase/auth";

import { setToken } from "./token";

export const getFirebaseIdToken = () =>
  new Promise((resolve, reject) => {
    const user = getAuth().currentUser;
    if (!user) return reject();
    else {
      return getIdToken(user).then((token) => {
        setToken(token);
        return resolve(token);
      });
    }
  });

export const firebaseLogin = (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

export const firebaseSignup = (email: string, password: string) => {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password);
};

export const firebaseLogout = () => {
  const auth = getAuth();
  return signOut(auth);
};

export const firebaseSendPasswordResetEmail = (email: string) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email);
};
