import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Typography, Grid, Link } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { firebaseLogin } from "../firebaseAuth";
import { RootState } from "../redux/store";
import { CustomButton } from "../ui";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email must not be empty"),
    password: yup.string().required("Password must not be empty")
  })
  .required();

export const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loggingIn, setLoggingIn] = useState(false);
  const { authenticated } = useSelector((state: RootState) => state.account);
  const { control, handleSubmit } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema)
  });
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={3}
      style={{ maxWidth: 300, margin: "auto", textAlign: "center" }}>
      <Grid item style={{ margin: "auto" }}>
        <img
          src="../logos/main.svg"
          alt="main-logo"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Password"
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <CustomButton
          variant="contained"
          color="primary"
          disabled={loggingIn && !authenticated}
          onClick={handleSubmit(async ({ email, password }) => {
            setLoggingIn(true);
            try {
              await firebaseLogin(email, password);
            } catch (e: any) {
              setLoggingIn(false);
              if (e.code === "auth/user-not-found") {
                enqueueSnackbar("Account not found, please try again", {
                  variant: "error"
                });
              } else if (e.code === "auth/wrong-password") {
                enqueueSnackbar("Incorrect password, please try again", {
                  variant: "error"
                });
              }
            }
          })}>
          Login
        </CustomButton>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2">
              Forgot password?{" "}
              <Link
                color="primary"
                onClick={() => history.push("/reset-password")}
                style={{ cursor: "pointer" }}>
                Reset Password
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              New around here?{" "}
              <Link
                color="primary"
                onClick={() => history.push("/create-account")}
                style={{ cursor: "pointer" }}>
                Create Account
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
