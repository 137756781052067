import { Unsubscribe, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { plantingsCollection } from "./firestore";
import {
  updatePlantings,
  deletePlanting,
  setPlantingsLoading
} from "./redux/dataSlice";
import { RootState } from "./redux/store";
import { IPlanting } from "./types";
import { processSnapshot } from "./useFirebase";

export interface IPlantingFilters {
  year?: string;
  fieldId?: string;
}

export const usePlantings = (
  filters: IPlantingFilters,
  options?: { skip?: boolean }
) => {
  const dispatch = useDispatch();
  const {
    account: { accountId, emailVerified },
    data: { plantings }
  } = useSelector((state: RootState) => state);
  const filteredPlantings = useMemo(
    () =>
      Object.values(plantings)
        .filter((planting) => {
          if (!filters.year && !filters.fieldId) return false;
          return (
            (filters.year ? planting.year === filters.year : true) &&
            (filters.fieldId ? planting.fieldId === filters.fieldId : true)
          );
        })
        .sort((a, b) => +b.year - +a.year),
    [plantings, filters.year, filters.fieldId]
  );

  useEffect(() => {
    let unsubPlantingsData: Unsubscribe;
    const constraints = [];
    if (filters.year) constraints.push(where("year", "==", filters.year));
    if (filters.fieldId)
      constraints.push(where("fieldId", "==", filters.fieldId));

    if (!options?.skip && accountId && emailVerified && constraints.length) {
      dispatch(setPlantingsLoading(true));
      unsubPlantingsData = onSnapshot(
        query(
          plantingsCollection,
          where("accountId", "==", accountId),
          ...constraints
        ),
        (snapshot) => {
          const { addedOrChanged, removed } =
            processSnapshot<IPlanting>(snapshot);
          if (addedOrChanged.length) dispatch(updatePlantings(addedOrChanged));
          if (removed.length) {
            removed.forEach((item) => {
              dispatch(deletePlanting(item));
            });
          }
          setTimeout(() => {
            dispatch(setPlantingsLoading(false));
          }, 100);
        }
      );
    }

    return () => unsubPlantingsData?.();
  }, [accountId, emailVerified, filters.year, filters.fieldId, options?.skip]);

  return { filteredPlantings };
};

export default usePlantings;
