import { useState, useEffect, RefObject } from "react";

export const useResize = (
  ref?: RefObject<HTMLElement | HTMLDivElement>,
  element?: HTMLElement | HTMLDivElement,
  effectArray?: any[]
) => {
  const [offsetWidth, setOffsetWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    const el = ref?.current || element;
    if (el) {
      setOffsetWidth(el.offsetWidth || 0);
      setOffsetHeight(el.offsetHeight || 0);
      setOffsetLeft(el.offsetLeft || 0);
      setOffsetTop(el.offsetTop || 0);
    }

    const handleResize = () => {
      setOffsetWidth(el?.offsetWidth || 0);
      setOffsetHeight(el?.offsetHeight || 0);
      setOffsetLeft(el?.offsetLeft || 0);
      setOffsetTop(el?.offsetTop || 0);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, element, ...(effectArray || [])]);

  return { offsetWidth, offsetHeight, offsetLeft, offsetTop };
};
