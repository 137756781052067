import {
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import Root from "./Root";
import { store } from "./redux/store";
import { ThemeWrapper } from "./theme";

const setVhProperty = () => {
  // Set variable for the fix for mobile Safari vh not working as expected
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

setVhProperty();
window.addEventListener("resize", () => {
  setVhProperty();
});

const useSnackbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    }
  })
);

const Snackbar: FC = ({ children }) => {
  const classes = useSnackbarStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: mobile ? "top" : "bottom",
        horizontal: mobile ? "center" : "right"
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error
      }}>
      {children}
    </SnackbarProvider>
  );
};

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ThemeWrapper>
        <CssBaseline />
        <Snackbar>
          <Root />
        </Snackbar>
      </ThemeWrapper>
    </Provider>
  </BrowserRouter>
);

export default App;
