import {
  Typography,
  makeStyles,
  Drawer,
  useMediaQuery,
  useTheme,
  Grid,
  FormControlLabel,
  Checkbox,
  withStyles
} from "@material-ui/core";
import { toJpeg } from "html-to-image";
import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { extractCropIdsFromPlantingListItems } from "../../helpers";
import { RootState } from "../../redux/store";
import { drawerWidth } from "../../theme";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CustomButton
} from "../../ui";
import { IPlantingListItem } from "../../types";

// Override font color to black, in case user is in dark mode
const BlackTypography = withStyles({
  root: {
    color: "black"
  }
})(Typography);

const useStyles = makeStyles({
  mapRoot: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative"
  },
  topBanner: {
    position: "absolute",
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  logo: {
    height: 30,
    backgroundColor: "#ffffff",
    padding: "2px 10px 2px 10px",
    borderRadius: "0px 0px 5px 0px"
  },
  plantingYearText: {
    padding: "2px 10px 2px 10px",
    borderRadius: "0px 0px 0px 5px",
    backgroundColor: "#ffffff"
  },
  key: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#ffffff",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  drawerContentTitle: {
    textAlign: "center",
    width: "100%",
    fontWeight: "bold",
    paddingBottom: 10,
    paddingTop: 5
  }
});

interface IExportDialogProps {
  plantingListItems: IPlantingListItem[];
  exportImageUrl: string;
  plantingYear: string;
  onClose: Function;
}

const ExportDialog = ({
  plantingListItems,
  exportImageUrl,
  plantingYear,
  onClose
}: IExportDialogProps) => {
  const { crops } = useSelector((state: RootState) => state.data);
  const cropIdsInCurrentPlantings =
    extractCropIdsFromPlantingListItems(plantingListItems);

  const classes = useStyles();
  const exportRef = useRef<any>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showCropKey, setShowCropKey] = useState(true);
  const [showPlantingYear, setShowPlantingYear] = useState(true);

  useEffect(() => {
    setDrawerOpen(!mobile);
  }, [mobile]);

  return (
    <Dialog
      open={!!exportImageUrl}
      fullScreen
      onClose={() => onClose()}
      style={{ right: mobile ? 0 : drawerWidth }}>
      <DialogTitle
        title="Export"
        onClose={() => onClose()}
        showMenuIcon={mobile}
        onMenuOpen={() => setDrawerOpen(true)}
      />
      <DialogContent>
        <Drawer
          variant={mobile ? "temporary" : "permanent"}
          anchor="right"
          open={mobile ? drawerOpen : true}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              width: drawerWidth
            }
          }}>
          <Grid container spacing={2} style={{ padding: 10 }}>
            <Typography className={classes.drawerContentTitle}>
              Options
            </Typography>

            <Grid item xs={12}>
              <FormControlLabel
                label="Crop Key"
                control={
                  <Checkbox
                    color="primary"
                    checked={plantingYear !== "" && showCropKey}
                    onChange={(_, checked) => setShowCropKey(checked)}
                    disabled={plantingYear === ""}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label="Planting Year"
                control={
                  <Checkbox
                    color="primary"
                    checked={plantingYear !== "" && showPlantingYear}
                    onChange={(_, checked) => setShowPlantingYear(checked)}
                    disabled={plantingYear === ""}
                  />
                }
              />
            </Grid>
          </Grid>
        </Drawer>

        <div
          ref={exportRef}
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #cccccc"
          }}>
          <div
            className={classes.mapRoot}
            style={{
              backgroundImage: `url(${exportImageUrl})`,
              backgroundPosition: "center"
            }}>
            <div className={classes.topBanner}>
              <img
                className={classes.logo}
                src="../logos/main.svg"
                alt="main-logo"
              />
              {plantingYear !== "" && showPlantingYear && (
                <BlackTypography
                  className={classes.plantingYearText}
                  variant="h5">
                  {plantingYear}
                </BlackTypography>
              )}
            </div>
            {showCropKey && (
              <div className={classes.key}>
                {cropIdsInCurrentPlantings.map((cropId) => (
                  <BlackTypography
                    key={cropId}
                    variant="body2"
                    style={{
                      borderLeft: `10px solid ${crops[cropId].colour}`,
                      padding: "0px 15px 0px 5px"
                    }}>
                    {crops[cropId].name}
                  </BlackTypography>
                ))}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClick={() => onClose()}>
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => {
            toJpeg(exportRef.current).then((dataUrl) => {
              const a = document.createElement("a");
              a.href = dataUrl;
              a.download =
                "Crop Map" + (plantingYear !== "" ? ` ${plantingYear}` : "");
              a.click();
            });
          }}>
          Download
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
