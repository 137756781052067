import { firebaseLogout } from "../firebaseAuth";
import { baseAxios, authenticatedAxios } from "../myAxios";
import { setToken } from "../token";
import { setAccount } from "./accountSlice";
import { AppDispatch } from "./store";

interface ICreateAccountData {
  accountId: string;
  accountName: string;
  email: string;
}

interface IEditAccountData {
  defaultMapLocation?: string;
  defaultMapStyle?: string;
}

export const loadAccountAction = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    authenticatedAxios
      .get("/account")
      .then((resp) => {
        dispatch(setAccount(resp.data));
        return resolve(resp.data);
      })
      .catch((err) => reject(err))
  );

export const createAccountAction =
  (accountData: ICreateAccountData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      baseAxios
        .post("/account", accountData)
        .then(() => {
          // @ts-ignore
          dispatch(loadAccountAction());
          return resolve(accountData);
        })
        .catch((err) => reject(err))
    );

export const editAccountAction =
  (editAccountData: IEditAccountData) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      authenticatedAxios
        .put("/account", editAccountData)
        .then((resp) => {
          dispatch(setAccount(resp.data));
          return resolve(resp);
        })
        .catch((err) => reject(err))
    );

export const logoutAction = () => async (dispatch: AppDispatch) => {
  await firebaseLogout();
  setToken("");
  localStorage.removeItem("darkMode");
  dispatch({ type: "DESTROY_SESSION" });
};
