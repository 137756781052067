let accessToken = "";

/**
 * Store the access token in memory for safety
 * @param token - an access token
 */
const setToken = (token: string) => {
  accessToken = token;
};

/**
 * Helper method for retrieving the token
 */
const getToken = () => accessToken;

export { setToken, getToken };
