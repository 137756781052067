import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  useTheme
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import ReactJoyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  ACTIONS,
  Step
} from "react-joyride";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../redux/store";
import { setWalkthroughRunning } from "../../redux/uiSlice";
import { CustomButton } from "../../ui";

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    width: 250,
    padding: 10
  }
}));

const steps: Step[] = [
  {
    title: "Search",
    disableBeacon: true,
    content: (
      <Typography>Use this search box to search for your farm.</Typography>
    ),
    target: ".mapboxgl-ctrl-geocoder--input",
    styles: {
      options: {
        zIndex: 10000
      }
    }
  },
  {
    title: "Save Location",
    disableBeacon: true,
    content: (
      <Typography>
        When you've found your farm, you can click here to save the location.
      </Typography>
    ),
    target: ".tour_step_locations_button",
    styles: {
      options: {
        zIndex: 10000
      }
    },
    // @ts-ignore
    insideDrawer: true
  },
  {
    title: "Switch Location",
    disableBeacon: true,
    content: (
      <Typography>
        All your saved locations will display in this dropdown, allowing you to
        easily switch between them.
      </Typography>
    ),
    target: ".tour_step_locations_dropdown",
    styles: {
      options: {
        zIndex: 10000
      }
    },
    // @ts-ignore
    insideDrawer: true
  },
  {
    title: "Draw Field",
    disableBeacon: true,
    content: (
      <Typography>
        Click this button to enter draw mode and add a field. Click on the map
        to add the coordinates, and double click if you want to stop adding
        coordinates.
      </Typography>
    ),
    target: ".tour_step_draw",
    styles: {
      options: {
        zIndex: 10000
      }
    }
  },
  {
    title: "Clear Drawing",
    disableBeacon: true,
    content: (
      <Typography>
        Click this button to clear the shape you have drawn.
      </Typography>
    ),
    target: ".tour_step_clear_draw",
    styles: {
      options: {
        zIndex: 10000
      }
    }
  },
  {
    title: "Save Field",
    disableBeacon: true,
    content: (
      <Typography>
        Once you are happy with the shape you have drawn, and have double
        clicked on the map, this button will allow you to save the field.
      </Typography>
    ),
    target: ".tour_step_save_field",
    styles: {
      options: {
        zIndex: 10000
      }
    }
  }
];

interface IWalkthroughProps {
  setMapDrawerOpen: (open: boolean) => void;
}

const Walkthrough = ({ setMapDrawerOpen }: IWalkthroughProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const [index, setIndex] = useState(0);
  const { walkthroughRunning } = useSelector((state: RootState) => state.ui);

  return (
    <ReactJoyride
      continuous
      run={walkthroughRunning}
      steps={steps}
      stepIndex={index}
      spotlightPadding={5}
      styles={{ options: { arrowColor: theme.palette.background.paper } }}
      callback={(data: CallBackProps) => {
        const { action, index, type, status } = data;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          dispatch(setWalkthroughRunning(false));
          setIndex(0);
        } else if (
          type === EVENTS.STEP_AFTER ||
          type === EVENTS.TARGET_NOT_FOUND
        ) {
          if (action === ACTIONS.STOP || action === ACTIONS.CLOSE) {
            dispatch(setWalkthroughRunning(false));
            setIndex(0);
          } else if (action === ACTIONS.NEXT || action === ACTIONS.PREV) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            // @ts-ignore
            if (!steps[stepIndex]?.insideDrawer) {
              setMapDrawerOpen(false);
              setIndex(stepIndex);
              // @ts-ignore
            } else if (steps[stepIndex]?.insideDrawer) {
              setMapDrawerOpen(true);
              setTimeout(() => {
                // Give the menu time to open first
                setIndex(stepIndex);
              }, 250);
            } else {
              setIndex(stepIndex);
            }
          }
        }
      }}
      tooltipComponent={({
        step,
        index,
        backProps,
        primaryProps,
        closeProps,
        tooltipProps
      }) => (
        <div className={classes.tooltipContainer} {...tooltipProps}>
          <Grid container spacing={2}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ paddingLeft: 10 }}>
              {step.title && (
                <Grid item>
                  <Typography variant="h6">{step.title}</Typography>
                </Grid>
              )}
              <Grid item>
                <IconButton onClick={closeProps.onClick}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {step.content}
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography>{`${index + 1} / ${steps.length}`}</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    {index > 0 && (
                      <Grid item>
                        <CustomButton
                          color="primary"
                          onClick={backProps.onClick}>
                          Back
                        </CustomButton>
                      </Grid>
                    )}
                    <Grid item>
                      <CustomButton
                        color="primary"
                        onClick={primaryProps.onClick}>
                        {index === steps.length - 1 ? "Finish" : "Next"}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    />
  );
};

export default Walkthrough;
