import { forwardRef, Ref } from "react";
import { useSelector } from "react-redux";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography,
  withStyles,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import { IPlantingListItem } from "../../types";
import { RootState } from "../../redux/store";
import { convertSquareMetersToAcres } from "../../helpers";

const TablePrintable = withStyles({
  root: {
    border: "1px solid #cccccc"
  }
})(MuiTable);

const TableCellPrintable = withStyles({
  root: {
    border: "1px solid #cccccc"
  }
})(MuiTableCell);

interface IPlantingsTableProps {
  plantingValues: IPlantingListItem[];
  onEditPlanting?: (id: string) => void;
  printable?: boolean;
}

const PlantingsTable = forwardRef(
  (
    { plantingValues, onEditPlanting, printable }: IPlantingsTableProps,
    ref: Ref<HTMLTableElement>
  ) => {
    const { fields, crops } = useSelector((state: RootState) => state.data);
    const TableComponent = printable ? TablePrintable : MuiTable;
    const TableCellComponent = printable ? TableCellPrintable : MuiTableCell;
    return (
      <TableComponent size="small" ref={ref}>
        <MuiTableHead>
          <TableCellComponent style={{ width: "30%" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Field
            </Typography>
          </TableCellComponent>
          <TableCellComponent style={{ width: "20%" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Size (acres)
            </Typography>
          </TableCellComponent>
          <TableCellComponent style={{ width: "30%" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Crop
            </Typography>
          </TableCellComponent>
          <TableCellComponent style={{ width: "10%" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Year
            </Typography>
          </TableCellComponent>
          {onEditPlanting && !printable && (
            <TableCellComponent style={{ width: "5%" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Actions
              </Typography>
            </TableCellComponent>
          )}
        </MuiTableHead>
        <MuiTableBody>
          {plantingValues.map((planting) => {
            const field = fields[planting.fieldId];
            const crop = crops[planting.cropId];
            return (
              <MuiTableRow
                key={
                  planting.plantingId ||
                  `${planting.year}:${planting.prolongedPlantingId}`
                }>
                <TableCellComponent>
                  <Typography variant="body2">{field?.name || ""}</Typography>
                </TableCellComponent>
                <TableCellComponent>
                  <Typography variant="body2">
                    {convertSquareMetersToAcres(field?.size) || ""}
                  </Typography>
                </TableCellComponent>
                <TableCellComponent
                  style={{
                    borderLeft: `5px solid ${crop?.colour}`
                  }}>
                  <Typography variant="body2">{crop?.name || ""}</Typography>
                </TableCellComponent>
                <TableCellComponent>
                  <Typography variant="body2">{planting.year}</Typography>
                </TableCellComponent>
                {onEditPlanting && !printable && (
                  <TableCellComponent>
                    {planting.plantingId && (
                      <IconButton
                        onClick={() => onEditPlanting?.(planting.plantingId!)}
                        size="small"
                        disabled={!planting.plantingId}
                        style={{ padding: 0 }}>
                        <Tooltip
                          placement="top"
                          title={
                            <Typography variant="body2">
                              Edit Planting
                            </Typography>
                          }>
                          <Edit />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCellComponent>
                )}
              </MuiTableRow>
            );
          })}
        </MuiTableBody>
      </TableComponent>
    );
  }
);

export default PlantingsTable;
