import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "./redux/store";
import { usePlantings, IPlantingFilters } from "./usePlantings";
import { IPlanting, IPlantingListItem, IProlongedPlanting } from "./types";
import { makeArrayOfNumbers } from "./helpers";

const generateListItemKey = (planting: IPlanting | IPlantingListItem) =>
  `${planting.year}:${planting.fieldId}`;

export const mergePlantings = (
  filteredPlantings: IPlanting[],
  prolongedPlantings: IProlongedPlanting[],
  filters: IPlantingFilters,
  minMaxPlantingYear: { min: string; max: string }
) => {
  const { year: yearFilter, fieldId: fieldIdFilter } = filters;
  const { min: minPlantingYear, max: maxPlantingYear } = minMaxPlantingYear;
  // Nothing to return if no filters have been provided
  if (!filters.year && !filters.fieldId) return [];

  // Key by year:fieldId:cropId to ensure unique results (plantings take priority over prolongedPlantings)
  const listItemMap: { [key: string]: IPlantingListItem } = {};

  Object.values(prolongedPlantings).forEach(
    ({ id, startYear, endYear, fieldId, cropId }) => {
      // Check for field filter match before continuing
      if (!fieldIdFilter || fieldIdFilter === fieldId) {
        // Generate a list of the possible years that this prolonged planting could relate to
        const years = makeArrayOfNumbers(
          startYear ? +startYear : +minPlantingYear,
          endYear ? +endYear : +maxPlantingYear
        );

        // Check for year filter match before continuing
        if (!yearFilter || years.includes(+yearFilter)) {
          if (yearFilter) {
            // Year filter has been specified so only add the planting for that year
            const listItem: IPlantingListItem = {
              prolongedPlantingId: id,
              cropId,
              fieldId,
              year: yearFilter
            };
            listItemMap[generateListItemKey(listItem)] = listItem;
          } else if (fieldIdFilter) {
            // No year filter specified, so push planting list item for each overlapping year
            years.forEach((year) => {
              const listItem: IPlantingListItem = {
                prolongedPlantingId: id,
                cropId,
                fieldId,
                year: `${year}`
              };
              listItemMap[generateListItemKey(listItem)] = listItem;
            });
          }
        }
      }
    }
  );

  // Add the single year plantings (these override prolonged plantings)
  filteredPlantings.forEach((planting) => {
    listItemMap[generateListItemKey(planting)] = {
      plantingId: planting.id,
      year: planting.year,
      fieldId: planting.fieldId,
      cropId: planting.cropId
    };
  });

  return Object.values(listItemMap).sort((a, b) => +b.year - +a.year);
};

// Merges plantings and prolongedPlantings together based upon the provided filters
export const useMergePlantings = (filters: IPlantingFilters) => {
  const { fieldId: fieldIdFilter, year: yearFilter } = filters;
  const { filteredPlantings } = usePlantings(filters);
  const { prolongedPlantings, minPlantingYear, maxPlantingYear } = useSelector(
    (state: RootState) => state.data
  );

  const mergedPlantings = useMemo(
    () =>
      mergePlantings(
        filteredPlantings,
        Object.values(prolongedPlantings),
        { year: yearFilter, fieldId: fieldIdFilter },
        { min: minPlantingYear, max: maxPlantingYear }
      ),
    [
      filteredPlantings,
      prolongedPlantings,
      fieldIdFilter,
      yearFilter,
      minPlantingYear,
      maxPlantingYear
    ]
  );

  return mergedPlantings;
};

export default useMergePlantings;
