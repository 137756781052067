import * as turf from "@turf/turf";
import { Feature } from "geojson";

import { TFields, TPlantings } from "./redux/dataSlice";
import { IPlantingListItem, IProlongedPlanting } from "./types";

const distinct = (value: any, index: number, self: any[]) =>
  self.indexOf(value) === index;

export const makeArrayOfNumbers = (start: number, end: number) => {
  const array = [];
  for (var i = start; i <= end; i++) {
    array.unshift(i);
  }
  return array;
};

export const getSizeOfFeatureInSquareMeters = (feature: Feature) =>
  `${turf.area(feature)}`;

export const squareMetersToAcres = (size: string | number) => {
  if (typeof size === "string") size = parseFloat(size);
  const acres = size / 4047;
  return `${acres.toFixed(2)}`;
};

export const convertSquareMetersToAcres = (size: string | number) => {
  if (typeof size === "string") size = parseFloat(size);
  const acres = size / 4047;
  return `${acres.toFixed(2)}`;
};

export const calculateTotalSizeOfFields = (fields: TFields) => {
  let totalSize = 0;
  Object.values(fields).forEach((field) => {
    totalSize = totalSize + parseFloat(field.size);
  });
  return totalSize;
};

export const extractCropIdsFromPlantings = (
  plantings: TPlantings,
  year: string
) => {
  if (year === "") return [];
  let plantingsValues = Object.values(plantings);
  if (!plantingsValues.length) return [];

  plantingsValues = plantingsValues.filter(
    (planting) => planting.year === year
  );

  return plantingsValues
    .map((planting) => planting.cropId)
    .filter(distinct)
    .sort();
};

export const extractCropIdsFromPlantingListItems = (
  plantingListItems: IPlantingListItem[]
) =>
  plantingListItems
    .map((item) => item.cropId)
    .filter(distinct)
    .sort();

// Get equivalent to (x)vh
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const getVh = (val: number) => `calc(var(--vh, 1vh) * ${val})`;

export const isEmptyString = (string: string) => {
  if (string.trim() === "") return true;
  return false;
};

// Returns true if the provided prolonged planting intersects with the provided year
export const prolongedPlantingYearMatch = (
  planting: IProlongedPlanting,
  year: string
) => {
  if (planting.startYear && planting.endYear) {
    // If both start and end year are specified, check year is inbetween them
    return +year >= +planting.startYear && +year <= +planting.endYear;
  } else if (planting.startYear) {
    // If only startYear is specified, check year is greater than or equal to startYear
    return +year >= +planting.startYear;
  } else {
    // If neither startYear or endYear is specified, consider it a match
    return true;
  }
};

export const startAndEndYearsToText = (
  startYear?: string,
  endYear?: string
) => {
  if (startYear && endYear) {
    return `Multi-year (${startYear} - ${endYear})`;
  } else if (startYear) {
    return `Multi-year (${startYear} onwards)`;
  } else {
    return "Permanent";
  }
};
