import { DrawMode } from "@mapbox/mapbox-gl-draw";
import { createSlice } from "@reduxjs/toolkit";

import { TMapStyle } from "../types";

interface IUiState {
  cropsDialogOpen: boolean;
  fieldsDialogOpen: boolean;
  plantingsDialogOpen: boolean;
  auditLogDialogOpen: boolean;
  mapEditFieldId: string;
  walkthroughRunning: boolean;
  mapLoaded: boolean;
  mapStyle: TMapStyle;
  editorMode: DrawMode;
  mapPlantingYear: string;
  mapLocationId: string;
  darkMode: boolean;
}

export const initialUiState: IUiState = {
  cropsDialogOpen: false,
  fieldsDialogOpen: false,
  plantingsDialogOpen: false,
  auditLogDialogOpen: false,
  mapEditFieldId: "",
  walkthroughRunning: false,
  mapLoaded: false,
  mapStyle: "monochrome",
  editorMode: "simple_select",
  mapPlantingYear: "",
  mapLocationId: "",
  darkMode: localStorage.getItem("darkMode") === "true"
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    setCropsDialogOpen: (state, { payload }) => {
      state.cropsDialogOpen = payload;
    },
    setFieldsDialogOpen: (state, { payload }) => {
      state.fieldsDialogOpen = payload;
    },
    setPlantingsDialogOpen: (state, { payload }) => {
      state.plantingsDialogOpen = payload;
    },
    setAuditLogDialogOpen: (state, { payload }) => {
      state.auditLogDialogOpen = payload;
    },
    setMapEditFieldId: (state, { payload }) => {
      state.mapEditFieldId = payload;
    },
    setWalkthroughRunning: (state, { payload }) => {
      state.walkthroughRunning = payload;
    },
    setMapLoaded: (state, { payload }) => {
      state.mapLoaded = payload;
    },
    setMapStyle: (state, { payload }) => {
      state.mapStyle = payload;
    },
    setEditorMode: (state, { payload }) => {
      state.editorMode = payload;
    },
    setMapPlantingYear: (state, { payload }) => {
      state.mapPlantingYear = payload;
    },
    setMapLocationId: (state, { payload }) => {
      state.mapLocationId = payload;
    },
    setDarkMode: (state, { payload }) => {
      state.darkMode = payload;
    }
  }
});

export const {
  setCropsDialogOpen,
  setFieldsDialogOpen,
  setPlantingsDialogOpen,
  setAuditLogDialogOpen,
  setMapEditFieldId,
  setWalkthroughRunning,
  setMapLoaded,
  setMapStyle,
  setEditorMode,
  setMapPlantingYear,
  setMapLocationId,
  setDarkMode
} = uiSlice.actions;

export default uiSlice.reducer;
