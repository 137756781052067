import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editPlantingAction } from "../../redux/dataActions";
import { RootState } from "../../redux/store";
import { colours } from "../../theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../ui";
import useHandleErrors from "../../useHandleErrors";
import DeletePlantingDialog from "./DeletePlantingDialog";

interface IPlantingDialogProps {
  open: boolean;
  onClose: () => void;
  plantingId: string;
}

export const EditPlantingDialog = ({
  open,
  onClose,
  plantingId
}: IPlantingDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const { fields, crops, plantings } = useSelector(
    (state: RootState) => state.data
  );

  const [saving, setSaving] = useState(false);
  const [selectedCropId, setSelectedCropId] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const cropValues = useMemo(() => Object.values(crops), [crops]);
  const planting = useMemo(
    () => plantings[plantingId],
    [plantings, plantingId]
  );
  const field = planting?.fieldId ? fields[planting.fieldId] : undefined;
  const selectedCrop = selectedCropId ? crops[selectedCropId] : undefined;

  // Update the crop state if the planting changes
  useEffect(() => {
    if (planting && selectedCropId !== planting.cropId)
      setSelectedCropId(planting.cropId);
  }, [planting]);

  const handleSubmit = async () => {
    if (planting?.id) {
      setSaving(true);
      try {
        await dispatch(
          editPlantingAction(planting.id, {
            cropId: selectedCropId
          })
        );
        enqueueSnackbar("Planting updated successfully", {
          variant: "success"
        });
        setSaving(false);
        onClose();
      } catch (e: any) {
        setSaving(false);
        handleErrors(e);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          onClose={onClose}
          title="Edit Single Year Planting"
          loading={saving}
        />

        <DialogContent
          data-testid="edit-planting-dialog-content"
          style={{
            borderTop: `10px solid ${selectedCrop?.colour || "#ffffff"}`
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                data-testid="edit-planting-dialog-title">
                You are editing the planting for "{field?.name}" in{" "}
                {planting?.year}.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                disabled={!cropValues.length}>
                <InputLabel id="cropSelectLabel">Crop</InputLabel>
                <Select
                  label="Crop"
                  labelId="cropSelectLabel"
                  value={selectedCropId}
                  onChange={(e) => setSelectedCropId(e.target.value as string)}
                  inputProps={{
                    "data-testid": "edit-planting-dialog-crop-select"
                  }}>
                  {cropValues.map((crop) => (
                    <MenuItem
                      key={crop.id}
                      value={crop.id}
                      style={{ borderLeft: `10px solid ${crop.colour}` }}>
                      {crop.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CustomButton
            variant="outlined"
            onClick={() => setDeleteConfirmationOpen(true)}
            startIcon={<Delete style={{ color: colours.red }} />}
            data-testid="edit-planting-dialog-delete-btn">
            Delete
          </CustomButton>
          <Grid item xs />
          <CustomButton
            variant="outlined"
            onClick={onClose}
            data-testid="edit-planting-dialog-cancel-btn">
            Cancel
          </CustomButton>
          <CustomButton
            color="primary"
            disabled={!selectedCropId || selectedCropId === planting?.cropId}
            onClick={handleSubmit}
            data-testid="edit-planting-dialog-save-btn">
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>

      {/* Dialog to confirm whether the user really wants to delete the planting */}
      {planting && (
        <DeletePlantingDialog
          open={deleteConfirmationOpen}
          planting={planting}
          onClose={() => setDeleteConfirmationOpen(false)}
          onPlantingDeleted={onClose}
        />
      )}
    </>
  );
};

export default EditPlantingDialog;
