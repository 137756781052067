import { useSnackbar } from "notistack";

const useHandleErrors = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleErrors = (error: any) => {
    const errorObj: { [key: string]: string } =
      error?.response?.data?.errors || {};
    const errors = Object.values(errorObj);
    if (errors.length) {
      errors.forEach((message) => {
        enqueueSnackbar(message, {
          variant: "error"
        });
      });
    }
  };

  return { handleErrors };
};

export default useHandleErrors;
