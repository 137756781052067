import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";

import {
  CropMap,
  Login,
  CreateAccount,
  Nav,
  Crops,
  Fields,
  Plantings,
  UnauthenticatedRoute,
  AuthenticatedRoute,
  VerifyEmail,
  ResetPassword,
  AuditLog
} from "./components";
import { RootState } from "./redux/store";
import {
  setAuditLogDialogOpen,
  setCropsDialogOpen,
  setFieldsDialogOpen,
  setMapEditFieldId
} from "./redux/uiSlice";
import useFirebase from "./useFirebase";

const Root = () => {
  useFirebase();
  const dispatch = useDispatch();
  const { crops, cropsLoading, fields, fieldsLoading } = useSelector(
    (state: RootState) => state.data
  );
  const { cropsDialogOpen, fieldsDialogOpen, auditLogDialogOpen } = useSelector(
    (state: RootState) => state.ui
  );

  return (
    <>
      <Fields
        open={fieldsDialogOpen}
        fields={fields}
        loading={fieldsLoading}
        onEditShape={(fieldId) => dispatch(setMapEditFieldId(fieldId))}
        onClose={() => dispatch(setFieldsDialogOpen(false))}
      />
      <Crops
        open={cropsDialogOpen}
        crops={crops}
        loading={cropsLoading}
        onClose={() => dispatch(setCropsDialogOpen(false))}
      />
      <Plantings />
      <AuditLog
        open={auditLogDialogOpen}
        onClose={() => dispatch(setAuditLogDialogOpen(false))}
      />
      <Nav />
      <Switch>
        <AuthenticatedRoute exact path="/" component={CropMap} />
        <AuthenticatedRoute exact path="/verifyEmail" component={VerifyEmail} />
        <UnauthenticatedRoute exact path="/login" component={Login} />
        <UnauthenticatedRoute
          exact
          path="/create-account"
          component={CreateAccount}
        />
        <UnauthenticatedRoute
          exact
          path="/reset-password"
          component={ResetPassword}
        />
      </Switch>
    </>
  );
};

export default Root;
