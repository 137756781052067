import { Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { createProlongedPlantingAction } from "../../../redux/dataActions";
import { RootState } from "../../../redux/store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../../ui";
import useHandleErrors from "../../../useHandleErrors";
import MultiYearLayout from "./MultiYearLayout";
import PermanentLayout from "./PermanentLayout";

const multiYearSchema = yup.object({
  fieldId: yup.string().min(1, "Please select a Field").required(),
  cropId: yup.string().min(1, "Please select a Crop").required(),
  startYear: yup.string().length(4, "Please select a Start Year"),
  endYear: yup
    .string()
    .test(
      "higher-than-start-year",
      "End Year must be higher than Start Year",
      (endYear, context) => {
        if (endYear) {
          const { startYear } = context.parent;
          return +startYear < +endYear;
        }
        return true;
      }
    )
});

const permanentSchema = yup.object({
  fieldId: yup.string().min(1, "Please select a Field").required(),
  cropId: yup.string().min(1, "Please select a Crop").required()
});

interface INewProlongedPlantingDialogProps {
  open: boolean;
  onClose: () => void;
}

export const NewProlongedPlantingDialog = ({
  open,
  onClose
}: INewProlongedPlantingDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const { crops, fields } = useSelector((state: RootState) => state.data);

  const [mode, setMode] = useState<"multi" | "permanent">("multi");
  const [saving, setSaving] = useState(false);

  const multiYearForm = useForm({
    defaultValues: { fieldId: "", cropId: "", startYear: "", endYear: "" },
    resolver: yupResolver(multiYearSchema)
  });
  const permanentForm = useForm({
    defaultValues: { fieldId: "", cropId: "" },
    resolver: yupResolver(permanentSchema)
  });

  const cropValues = useMemo(
    () => Object.values(crops).sort((a, b) => a.name.localeCompare(b.name)),
    [crops]
  );
  const fieldValues = useMemo(
    () => Object.values(fields).sort((a, b) => a.name.localeCompare(b.name)),
    [fields]
  );
  const noFieldsOrCrops = !cropValues.length || !fieldValues.length;

  const handleClose = () => {
    onClose();
    multiYearForm.reset();
    permanentForm.reset();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle
        onClose={handleClose}
        title="New Prolonged Planting"
        loading={saving}
      />
      <DialogContent data-testid="new-prolonged-planting-dialog-content">
        {!noFieldsOrCrops ? (
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Grid container justifyContent="center">
                <ToggleButtonGroup
                  size="small"
                  value={mode}
                  exclusive
                  onChange={(_, mode) => setMode(mode)}>
                  <ToggleButton value="multi">
                    <Typography>Multi-year</Typography>
                  </ToggleButton>
                  <ToggleButton value="permanent">
                    <Typography>Permanent</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid item>
              {mode === "multi" ? (
                <FormProvider {...multiYearForm}>
                  <MultiYearLayout
                    fieldValues={fieldValues}
                    cropValues={cropValues}
                  />
                </FormProvider>
              ) : (
                <FormProvider {...permanentForm}>
                  <PermanentLayout
                    fieldValues={fieldValues}
                    cropValues={cropValues}
                  />
                </FormProvider>
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography align="center">
            You must add at least one field and at least one crop before you can
            create a planting.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid item xs />
        <CustomButton
          variant="outlined"
          onClick={() => handleClose()}
          data-testid="new-prolonged-planting-dialog-cancel-btn">
          Cancel
        </CustomButton>
        {!noFieldsOrCrops && (
          <CustomButton
            color="primary"
            disabled={saving}
            onClick={() => {
              switch (mode) {
                case "multi":
                  multiYearForm.handleSubmit(
                    async ({ fieldId, cropId, startYear, endYear }) => {
                      setSaving(true);
                      try {
                        // Trigger the prolonged planting creation
                        await dispatch(
                          createProlongedPlantingAction({
                            fieldId,
                            cropId,
                            startYear,
                            endYear
                          })
                        );
                        enqueueSnackbar(
                          "Multi-year planting created successfully",
                          {
                            variant: "success"
                          }
                        );
                        setSaving(false);
                        handleClose();
                      } catch (e: any) {
                        setSaving(false);
                        handleErrors(e);
                      }
                    },
                    (err) => {
                      Object.values(err).forEach(({ message }) => {
                        enqueueSnackbar(message, {
                          variant: "error"
                        });
                      });
                    }
                  )();
                  break;
                case "permanent":
                  permanentForm.handleSubmit(
                    async ({ fieldId, cropId }) => {
                      setSaving(true);
                      try {
                        // Trigger the prolonged planting creation
                        await dispatch(
                          createProlongedPlantingAction({
                            fieldId,
                            cropId
                          })
                        );
                        enqueueSnackbar(
                          "Permanent planting created successfully",
                          {
                            variant: "success"
                          }
                        );
                        setSaving(false);
                        handleClose();
                      } catch (e: any) {
                        setSaving(false);
                        handleErrors(e);
                      }
                    },
                    (err) => {
                      Object.values(err).forEach(({ message }) => {
                        enqueueSnackbar(message, {
                          variant: "error"
                        });
                      });
                    }
                  )();
                  break;
              }
            }}
            data-testid="new-prolonged-planting-dialog-save-btn">
            Save
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewProlongedPlantingDialog;
