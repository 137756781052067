import { PropsWithChildren } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Tooltip,
  Typography
} from "@material-ui/core";

interface ICustomButtonProps extends MuiButtonProps {
  tooltipText?: string;
}

interface ICustomButtonContainerProps {
  tooltipText?: string;
}

const Container = ({
  tooltipText,
  children
}: PropsWithChildren<ICustomButtonContainerProps>) => {
  return tooltipText ? (
    <Tooltip
      placement="top"
      title={<Typography variant="body2">{tooltipText}</Typography>}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export const CustomButton = ({
  tooltipText,
  disableElevation,
  variant,
  children,
  ...rest
}: ICustomButtonProps) => (
  <Container tooltipText={tooltipText}>
    <MuiButton
      variant={variant || "contained"}
      disableElevation
      disableRipple
      {...rest}>
      {children}
    </MuiButton>
  </Container>
);

export default CustomButton;
