import { Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { createPlantingAction } from "../../../redux/dataActions";
import { RootState } from "../../../redux/store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../../ui";
import useHandleErrors from "../../../useHandleErrors";
import SingleYearLayout from "./SingleYearLayout";

const singleYearSchema = yup.object({
  fieldId: yup.string().min(1, "Please select a Field").required(),
  cropId: yup.string().min(1, "Please select a Crop").required(),
  year: yup.string().length(4, "Please select a Year").required()
});

interface INewPlantingDialogProps {
  open: boolean;
  onClose: () => void;
  fieldId?: string;
  year?: string;
  onPlantingCreated?: (year: string) => void;
}

export const NewPlantingDialog = ({
  open,
  onClose,
  fieldId,
  year,
  onPlantingCreated
}: INewPlantingDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const { crops, fields } = useSelector((state: RootState) => state.data);
  const [saving, setSaving] = useState(false);

  const form = useForm({
    defaultValues: { fieldId: "", cropId: "", year: "" },
    resolver: yupResolver(singleYearSchema)
  });

  const cropValues = useMemo(
    () => Object.values(crops).sort((a, b) => a.name.localeCompare(b.name)),
    [crops]
  );
  const fieldValues = useMemo(
    () => Object.values(fields).sort((a, b) => a.name.localeCompare(b.name)),
    [fields]
  );
  const noFieldsOrCrops = !cropValues.length || !fieldValues.length;

  useEffect(() => {
    form.setValue("year", year || "");
  }, [year]);

  useEffect(() => {
    if (fieldId) {
      form.setValue("fieldId", fieldId);
    }
  }, [fieldId]);

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle
        onClose={handleClose}
        title="New Single Year Planting"
        loading={saving}
      />
      <DialogContent data-testid="new-planting-dialog-content">
        {!noFieldsOrCrops ? (
          <FormProvider {...form}>
            <SingleYearLayout
              fieldValues={fieldValues}
              cropValues={cropValues}
            />
          </FormProvider>
        ) : (
          <Typography align="center">
            You must add at least one field and at least one crop before you can
            create a planting.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Grid item xs />
        <CustomButton
          variant="outlined"
          onClick={() => handleClose()}
          data-testid="new-planting-dialog-cancel-btn">
          Cancel
        </CustomButton>
        {!noFieldsOrCrops && (
          <CustomButton
            color="primary"
            disabled={saving}
            onClick={() => {
              form.handleSubmit(
                async ({ fieldId, cropId, year }) => {
                  setSaving(true);
                  try {
                    // Trigger the planting creation
                    await dispatch(
                      createPlantingAction({
                        fieldId,
                        cropId,
                        year
                      })
                    );
                    enqueueSnackbar("Planting created successfully", {
                      variant: "success"
                    });
                    onPlantingCreated?.(year);
                    setSaving(false);
                    handleClose();
                  } catch (e: any) {
                    setSaving(false);
                    handleErrors(e);
                  }
                },
                (err) => {
                  Object.values(err).forEach(({ message }) => {
                    enqueueSnackbar(message, {
                      variant: "error"
                    });
                  });
                }
              )();
            }}
            data-testid="new-planting-dialog-save-btn">
            Save
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewPlantingDialog;
