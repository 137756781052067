import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import CropColour from "../../CropColour";

export const CropAuditCreate = ({ newData }: IAuditLogEntry) => {
  return (
    <>
      <Typography>Created a new Crop "{newData.name}"</Typography>
      <ul style={{ margin: 0 }}>
        <li>
          Colour: <CropColour colour={newData.colour} />
        </li>
      </ul>
    </>
  );
};

export default CropAuditCreate;
