import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";
import { EditLocationOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import { setMapLocationId } from "../../../redux/uiSlice";
import { CustomButton } from "../../../ui";

interface IDrawerLocationsProps {
  setLocationsDialogOpen: Function;
}

export const DrawerLocations = ({
  setLocationsDialogOpen
}: IDrawerLocationsProps) => {
  const dispatch = useDispatch();
  const { locations } = useSelector((state: RootState) => state.data);
  const { mapLocationId } = useSelector((state: RootState) => state.ui);
  const locationValues = Object.values(locations);
  const disabledLocationSelect = locationValues.length === 0;

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          className="tour_step_locations_dropdown"
          disabled={disabledLocationSelect}>
          <InputLabel id="current-location-label">Location</InputLabel>
          <Select
            label="Location"
            labelId="current-location-label"
            value={mapLocationId}
            disabled={disabledLocationSelect}
            onChange={(event) => {
              dispatch(setMapLocationId(event.target.value as string));
            }}>
            {locationValues.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <CustomButton
          tooltipText="Edit Locations"
          className="tour_step_locations_button"
          color="primary"
          onClick={() => setLocationsDialogOpen(true)}
          style={{ marginLeft: 10 }}>
          <EditLocationOutlined />
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default DrawerLocations;
