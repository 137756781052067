import { useMemo, useState } from "react";
import {
  Typography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import {
  CustomButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "../../ui";
import NewProlongedPlantingDialog from "../PlantingDialog/NewPlantingDialog/NewProlongedPlantingDialog";
import { DeleteProlongedPlantingDialog } from "../PlantingDialog";

interface IProlongedPlantingsProps {
  open: boolean;
  onClose: () => void;
}

export const ProlongedPlantings = ({
  open,
  onClose
}: IProlongedPlantingsProps) => {
  const { prolongedPlantings, prolongedPlantingsLoading, fields, crops } =
    useSelector((state: RootState) => state.data);
  const sortedProlongedPlantings = useMemo(
    () =>
      Object.values(prolongedPlantings).sort((a, b) =>
        b.createdOn.localeCompare(a.createdOn)
      ),
    [prolongedPlantings]
  );
  const [newPlantingOpen, setNewPlantingOpen] = useState(false);
  const [deletePlantingId, setDeletePlantingId] = useState("");
  const plantingToDelete = useMemo(
    () =>
      sortedProlongedPlantings.find(
        (planting) => planting.id === deletePlantingId
      ),
    [deletePlantingId, sortedProlongedPlantings]
  );
  return (
    <>
      <NewProlongedPlantingDialog
        open={newPlantingOpen}
        onClose={() => setNewPlantingOpen(false)}
      />
      {plantingToDelete && (
        <DeleteProlongedPlantingDialog
          open={!!deletePlantingId}
          planting={plantingToDelete}
          onClose={() => setDeletePlantingId("")}
        />
      )}
      <Dialog open={open} onClose={() => onClose()} maxWidth="lg">
        <DialogTitle title="Prolonged Plantings" onClose={() => onClose()} />
        <DialogContent>
          {sortedProlongedPlantings.length > 0 ? (
            <MuiTable size="small">
              <MuiTableHead>
                <MuiTableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Field
                  </Typography>
                </MuiTableCell>
                <MuiTableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Crop
                  </Typography>
                </MuiTableCell>
                <MuiTableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Start Year
                  </Typography>
                </MuiTableCell>
                <MuiTableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    End Year
                  </Typography>
                </MuiTableCell>
                <MuiTableCell>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Actions
                  </Typography>
                </MuiTableCell>
              </MuiTableHead>
              <MuiTableBody>
                {sortedProlongedPlantings.map((planting) => {
                  const field = fields[planting.fieldId];
                  const crop = crops[planting.cropId];
                  return (
                    <MuiTableRow key={planting.id}>
                      <MuiTableCell>
                        <Typography variant="body2">
                          {field?.name || ""}
                        </Typography>
                      </MuiTableCell>
                      <MuiTableCell
                        style={{
                          borderLeft: `5px solid ${crop?.colour}`
                        }}>
                        <Typography variant="body2">
                          {crop?.name || ""}
                        </Typography>
                      </MuiTableCell>
                      <MuiTableCell>
                        <Typography variant="body2">
                          {planting.startYear || "-"}
                        </Typography>
                      </MuiTableCell>
                      <MuiTableCell>
                        <Typography variant="body2">
                          {planting.endYear || "-"}
                        </Typography>
                      </MuiTableCell>
                      <MuiTableCell>
                        <IconButton
                          onClick={() => setDeletePlantingId(planting.id!)}
                          size="small"
                          style={{ padding: 0 }}>
                          <Tooltip
                            placement="top"
                            title={
                              <Typography variant="body2">
                                Delete Planting
                              </Typography>
                            }>
                            <Delete />
                          </Tooltip>
                        </IconButton>
                      </MuiTableCell>
                    </MuiTableRow>
                  );
                })}
              </MuiTableBody>
            </MuiTable>
          ) : prolongedPlantingsLoading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: 400 }}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body2">
                  Prolonged Plantings allow you to configure a planting record
                  that spans multiple consecutive years, saving you the effort
                  of creating an individual planting record for each year.
                  Prolonged Plantings can be Permanent or optionally given a
                  Start Year and End Year.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Click the "Add Prolonged Planting" button below to create one.
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="primary"
            onClick={() => setNewPlantingOpen(true)}
            startIcon={<Add />}>
            Add Prolonged Planting
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProlongedPlantings;
