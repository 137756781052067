import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const PlantingAuditDelete = ({ oldData }: IAuditLogEntry) => {
  const { fields } = useSelector((state: RootState) => state.data);
  return (
    <Typography>
      Deleted the Planting for "
      {fields[oldData.fieldId]?.name || "Unknown Field"}" in {oldData.year}
    </Typography>
  );
};

export default PlantingAuditDelete;
