import {
  Typography,
  Drawer,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  IconButton,
  Tooltip,
  DialogActions
} from "@material-ui/core";
import { Add, InfoOutlined, Print } from "@material-ui/icons";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

import { RootState } from "../../redux/store";
import { setPlantingsDialogOpen } from "../../redux/uiSlice";
import { drawerWidth } from "../../theme";
import { Dialog, DialogContent, DialogTitle, CustomButton } from "../../ui";
import Loading from "../Loading";
import EditPlantingDialog from "../PlantingDialog/EditPlantingDialog";
import { NewPlantingDialog } from "../PlantingDialog/NewPlantingDialog";
import InfoContent from "./InfoContent";
import MobilePlantings from "./MobilePlantings";
import NoPlantings from "./NoPlantings";
import PlantingFilters from "./PlantingFilters";
import PlantingsTable from "./PlantingsTable";
import useMergePlantings from "../../useMergePlantings";
import ProlongedPlantings from "./ProlongedPlantings";

export const addPlantingText = "Add Single Year Planting";

interface IInfoDialogProps {
  open: boolean;
  onClose: Function;
}

const InfoDialog = ({ open, onClose }: IInfoDialogProps) => (
  <Dialog open={open} onClose={() => onClose()}>
    <DialogTitle title="Info" onClose={() => onClose()} />
    <DialogContent>
      <InfoContent />
    </DialogContent>
  </Dialog>
);

export const Plantings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const tableRef = useRef<any>();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { plantingsLoading, fieldsLoading, cropsLoading } = useSelector(
    (state: RootState) => state.data
  );
  const { plantingsDialogOpen } = useSelector((state: RootState) => state.ui);

  const [filteredPlantingYear, setFilteredPlantingYear] = useState("");
  const [filteredFieldId, setFilteredFieldId] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [editPlantingId, setEditPlantingId] = useState("");
  const [newPlantingOpen, setNewPlantingOpen] = useState(false);
  const [prolongedPlantingsOpen, setProlongedPlantingsOpen] = useState(false);

  const loading = plantingsLoading || fieldsLoading || cropsLoading;
  const filtered = !!(filteredPlantingYear || filteredFieldId);

  const mergedPlantings = useMergePlantings({
    year: filteredPlantingYear,
    fieldId: filteredFieldId
  });

  useEffect(() => {
    setDrawerOpen(!mobile);
  }, [mobile]);

  return (
    <Dialog
      fullScreen
      open={plantingsDialogOpen}
      onClose={() => dispatch(setPlantingsDialogOpen(false))}
      style={{ right: mobile ? 0 : drawerWidth }}>
      <DialogTitle
        title="Plantings"
        onClose={() => dispatch(setPlantingsDialogOpen(false))}
        showMenuIcon={mobile}
        onMenuOpen={() => setDrawerOpen(true)}
      />
      <NewPlantingDialog
        open={newPlantingOpen}
        onClose={() => setNewPlantingOpen(false)}
        year={filteredPlantingYear}
        onPlantingCreated={(year) => setFilteredPlantingYear(year)}
      />
      <EditPlantingDialog
        open={!!editPlantingId}
        plantingId={editPlantingId}
        onClose={() => setEditPlantingId("")}
      />
      <ProlongedPlantings
        open={prolongedPlantingsOpen}
        onClose={() => setProlongedPlantingsOpen(false)}
      />
      <DialogContent>
        <Drawer
          variant={mobile ? "temporary" : "permanent"}
          anchor="right"
          open={mobile ? drawerOpen : true}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              width: drawerWidth,
              overflow: "hidden"
            }
          }}>
          <Grid container spacing={3} style={{ padding: 20 }}>
            <Grid item xs={12}>
              <PlantingFilters
                plantingYear={filteredPlantingYear}
                fieldId={filteredFieldId}
                onChangePlantingYear={(year) => setFilteredPlantingYear(year)}
                onChangeFieldId={(fieldId) => setFilteredFieldId(fieldId)}
              />
            </Grid>

            {tableRef?.current && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <ReactToPrint
                      pageStyle={`
                      @page {
                          /* Remove browser default header (title) and footer (url) */
                          margin: 0;
                      }
                      @media print {
                        *,
                        *:before,
                        *:after {
                            color: #000 !important;
                        }
                      }
                  `}
                      content={() => tableRef.current}
                      onBeforePrint={() => setDrawerOpen(false)}
                      trigger={() => (
                        <CustomButton
                          variant="contained"
                          color="primary"
                          disabled={!mergedPlantings.length}
                          startIcon={<Print />}>
                          Print Table
                        </CustomButton>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Drawer>
        {!loading ? (
          <>
            {mergedPlantings.length > 0 ? (
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Tooltip
                        title={<Typography variant="body2">Info</Typography>}
                        placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => setInfoDialogOpen(true)}>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ height: 0 }}>
                  {mobile ? (
                    <MobilePlantings
                      plantingValues={mergedPlantings}
                      onEditPlanting={(id) => setEditPlantingId(id)}
                    />
                  ) : (
                    <PlantingsTable
                      plantingValues={mergedPlantings}
                      onEditPlanting={(id) => setEditPlantingId(id)}
                    />
                  )}
                </Grid>
              </Grid>
            ) : (
              <NoPlantings filtered={filtered} />
            )}
          </>
        ) : (
          <Loading />
        )}
        <InfoDialog
          open={infoDialogOpen}
          onClose={() => setInfoDialogOpen(false)}
        />
        {!mobile && (
          <div style={{ display: "none" }}>
            {/* Table without the edit column for printing */}
            <PlantingsTable
              plantingValues={mergedPlantings}
              ref={tableRef}
              printable
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="outlined"
          onClick={() => setProlongedPlantingsOpen(true)}>
          View Prolonged Plantings
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => setNewPlantingOpen(true)}
          disabled={loading}
          startIcon={<Add />}>
          {addPlantingText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default Plantings;
