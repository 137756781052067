import { TextField, Typography } from "@material-ui/core";
import { Map } from "mapbox-gl";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { createLocationAction } from "../../redux/dataActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CustomButton
} from "../../ui";
import useHandleErrors from "../../useHandleErrors";

interface INewLocationDialogProps {
  open: boolean;
  onClose: () => void;
  map: Map | undefined;
}

export const NewLocationDialog = ({
  open,
  onClose,
  map
}: INewLocationDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);

  const close = () => {
    setName("");
    onClose();
  };

  const onSave = async () => {
    setSaving(true);
    try {
      const center = map?.getBounds().getCenter();
      const zoom = map?.getZoom();
      if (center && zoom !== undefined && zoom >= 0) {
        await dispatch(
          createLocationAction({
            name,
            lat: `${center.lat}`,
            long: `${center.lng}`,
            zoom: `${zoom}`
          })
        );
        enqueueSnackbar("Location created successfully", {
          variant: "success"
        });
        setSaving(false);
        close();
      } else {
        setSaving(false);
        enqueueSnackbar(
          "Map location could not be retrieved, please try again",
          {
            variant: "error"
          }
        );
      }
    } catch (e: any) {
      setSaving(false);
      handleErrors(e);
    }
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle
        title="New Location"
        onClose={() => close()}
        loading={saving}
      />
      <DialogContent>
        <Typography
          variant="body2"
          style={{ paddingBottom: 20, textAlign: "center" }}>
          The current position of the map will be saved with the name you enter
          here.
        </Typography>
        <TextField
          size="small"
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => {
            if (e.target.value.length <= 40) setName(e.target.value);
          }}
          inputProps={{ "data-testid": "new-location-dialog-name-input" }}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="outlined"
          onClick={() => close()}
          data-testid="new-location-dialog-cancel-btn">
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          disabled={!name}
          onClick={onSave}
          data-testid="new-location-dialog-save-btn">
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewLocationDialog;
