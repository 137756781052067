import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import accountSlice, { initialAccountState } from "./accountSlice";
import dataSlice, { initialDataState } from "./dataSlice";
import uiSlice, { initialUiState } from "./uiSlice";

const initialState = {
  data: initialDataState,
  account: initialAccountState,
  ui: initialUiState
};

const reducers = combineReducers({
  data: dataSlice,
  account: accountSlice,
  ui: uiSlice
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "DESTROY_SESSION") {
    state = initialState;
  }

  return reducers(state, action);
};

const store = configureStore({ reducer: rootReducer });

// For unit testing with no data
const initialStore = configureStore({
  reducer: (_, action) => reducers(initialState, action)
});

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
type RootState = ReturnType<typeof rootReducer>;

export { store, initialStore };
export type { AppDispatch, RootState };
