import {
  Grid,
  Typography,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { editAccountAction } from "../../../redux/accountActions";
import { RootState } from "../../../redux/store";
import { setMapStyle } from "../../../redux/uiSlice";

const useStyles = makeStyles((theme) => ({
  mapStyleToggleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  mapStyleToggleButtonGroup: { width: "100%" },
  mapStyleToggleButton: {
    flex: 1,
    color: theme.palette.text.primary,
    "&.Mui-selected": { color: theme.palette.text.primary },
    "&.Mui-disabled": { color: theme.palette.text.disabled }
  }
}));

export const DrawerMapStyle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { mapStyle, editorMode } = useSelector((state: RootState) => state.ui);
  const { defaultMapStyle } = useSelector((state: RootState) => state.account);
  const disableStyleChange =
    editorMode === "direct_select" || editorMode === "draw_polygon";

  const changeMapStyle = (style: string) => {
    if (!style) return; // Clicking the currently selected style triggers this method with null style, weirdly
    if (style === mapStyle) return; // If the style matches the current style, don't continue
    dispatch(setMapStyle(style));
  };

  const onChangeDefaultMapStyle = async (mapStyle: string) => {
    try {
      await dispatch(editAccountAction({ defaultMapStyle: mapStyle }));
      enqueueSnackbar("Default Map Style updated successfully", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error"
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.mapStyleToggleContainer}>
        <ToggleButtonGroup
          className={classes.mapStyleToggleButtonGroup}
          size="small"
          value={mapStyle || "satellite"}
          exclusive
          onChange={(_, style) => changeMapStyle(style)}>
          <ToggleButton
            className={classes.mapStyleToggleButton}
            value="monochrome"
            disabled={disableStyleChange}>
            <Typography>Monochrome</Typography>
          </ToggleButton>
          <ToggleButton
            className={classes.mapStyleToggleButton}
            value="satellite"
            disabled={disableStyleChange}>
            <Typography>Satellite</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="defaultMapStyleLabel">Default Map Style</InputLabel>
          <Select
            label="Default Map Style"
            labelId="defaultMapStyleLabel"
            value={defaultMapStyle}
            onChange={(e) => onChangeDefaultMapStyle(e.target.value as string)}>
            <MenuItem value="satellite">Satellite</MenuItem>
            <MenuItem value="monochrome">Monochrome</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DrawerMapStyle;
