import MapboxDraw, { DrawMode } from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
  makeStyles,
  Drawer as MuiDrawer,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  withStyles
} from "@material-ui/core";
import { Menu, HelpOutline } from "@material-ui/icons";
import { Feature } from "geojson";
import mapboxgl, { LngLatLike, Map, NavigationControl } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";

import { DeleteFieldDialog, LocationsDialog } from "..";
import { getSizeOfFeatureInSquareMeters, getVh } from "../../helpers";
import { useResize } from "../../hooks";
import { editFieldAction } from "../../redux/dataActions";
import { RootState } from "../../redux/store";
import {
  setMapEditFieldId,
  setMapLoaded,
  setMapLocationId,
  setWalkthroughRunning,
  setMapStyle,
  setMapPlantingYear,
  setEditorMode
} from "../../redux/uiSlice";
import { drawerWidth } from "../../theme";
import { CustomButton } from "../../ui";
import useGeojson from "../../useGeojson";
import useHandleErrors from "../../useHandleErrors";
import FieldDialog from "../Fields/FieldDialog";
import { navHeight } from "../Nav";
import EditPlantingDialog from "../PlantingDialog/EditPlantingDialog";
import { NewPlantingDialog } from "../PlantingDialog/NewPlantingDialog";
import DrawControls from "./DrawControls";
import { CropMapDrawer } from "./Drawer/Drawer";
import ExportDialog from "./ExportDialog";
import FieldHoverTooltip from "./FieldHoverTooltip";
import Walkthrough from "./Walkthrough";
import useMapStyle from "./useMapStyle";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

let dragging = false;

const MapControlStyleContainer = withStyles((theme) => ({
  root: {
    "& .mapboxgl-ctrl-group": {
      background: theme.palette.background.paper,
      boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
      border: "1px solid rgba(255, 255, 255, 0.3)"
    },
    "& .mapboxgl-ctrl-group button + button": {
      borderTop: `1px solid ${theme.palette.background.default}`
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon": {
      backgroundImage: `url("../logos/${
        theme.palette.type === "dark" ? "add-light" : "add-dark"
      }.svg")`
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon": {
      backgroundImage: `url("../logos/${
        theme.palette.type === "dark" ? "remove-light" : "remove-dark"
      }.svg")`
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon": {
      backgroundImage: `url("../logos/${
        theme.palette.type === "dark" ? "explore-light" : "explore-dark"
      }.svg")`
    },
    "& .mapboxgl-ctrl-geocoder": {
      background: theme.palette.background.paper,
      borderRadius: 4,
      border: "1px solid rgba(255, 255, 255, 0.3)"
    },
    "& .mapboxgl-ctrl-geocoder--button": {
      background: "transparent"
    },
    "& .mapboxgl-ctrl-geocoder--input": {
      color: theme.palette.text.primary
    },
    "& .mapboxgl-ctrl-geocoder--input::placeholder": {
      color: theme.palette.text.primary
    },
    "& .mapboxgl-ctrl-geocoder--icon": {
      fill: theme.palette.text.primary
    },
    "& .mapboxgl-ctrl-geocoder .suggestions li": {
      background: theme.palette.background.paper
    },
    "& .mapboxgl-ctrl-geocoder .suggestions > li > a:hover": {
      background: theme.palette.background.default
    },
    "& .mapboxgl-ctrl-geocoder .suggestions > .active > a": {
      background: theme.palette.background.default
    },
    "& .mapboxgl-ctrl-geocoder--suggestion": {
      color: theme.palette.text.primary
    }
  }
}))(Box);

const useStyles = makeStyles((theme) => ({
  menuIconContainer: {
    right: 10,
    position: "absolute",
    zIndex: 2
  },
  menuButton: {
    // TODO: make storybook button
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      opacity: 0.9
    }
  },
  menuIcon: {
    color: theme.palette.text.primary
  },
  helpButton: {
    position: "absolute",
    zIndex: 1,
    left: 10
  }
}));

export const CropMap = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const theme = useTheme();

  const permanentDrawerBool = useMediaQuery(theme.breakpoints.up("lg"));
  const extraExtraSmallScreen = useMediaQuery(
    theme.breakpoints.between(0, 370)
  );

  const {
    account: { authenticated, defaultMapLocation, defaultMapStyle },
    data: {
      crops,
      locations,
      locationsLoading,
      fieldsLoading,
      plantingsLoading,
      cropsLoading
    },
    ui: {
      mapEditFieldId,
      mapLoaded,
      mapLocationId,
      mapPlantingYear,
      editorMode
    }
  } = useSelector((state: RootState) => state);
  const { geojson, plantingListItems } = useGeojson();
  const { mapboxStyle } = useMapStyle();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [plantingDialogOpen, setPlantingDialogOpen] = useState(false);
  const [selectedSavedFeature, setSelectedSavedFeature] = useState<Feature>();
  const [selectedEditFeature, setSelectedEditFeature] = useState<Feature>();
  const [drawnFeature, setDrawnFeature] = useState<Feature>();
  const [newFieldDialogOpen, setNewFieldDialogOpen] = useState(false);
  const [drawnFeatureBool, setDrawnFeatureBool] = useState(false);
  const [deleteFieldOpen, setDeleteFieldOpen] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState<Feature>();
  const [hoveredCoordinates, setHoveredCoordinates] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [locationsDialogOpen, setLocationsDialogOpen] = useState(false);
  const [savingField, setSavingField] = useState(false);
  const [exportImageUrl, setExportImageUrl] = useState("");

  const mapContainerRef = useRef<any>();
  const mapRef = useRef<Map>();
  const draw = useRef<MapboxDraw>();
  const geocoder = document.getElementsByClassName(
    "mapboxgl-ctrl-geocoder--input"
  )?.[0] as HTMLElement;

  const { offsetHeight: geocoderOffsetHeight } = useResize(undefined, geocoder);
  const { offsetWidth: mapContainerWidth } = useResize(
    mapContainerRef,
    undefined,
    [permanentDrawerBool]
  );

  // Load the default map style
  useEffect(() => {
    if (mapLoaded) {
      dispatch(setMapStyle(defaultMapStyle));
    }
  }, [mapLoaded, defaultMapStyle]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapboxStyle);
    }
  }, [mapboxStyle, mapRef]);

  // Fly to the desired location when it changes
  useEffect(() => {
    const location = locations[mapLocationId];
    if (location) {
      mapRef.current?.flyTo({
        center: [parseFloat(location.long), parseFloat(location.lat)],
        zoom: parseFloat(location.zoom)
      });
    }
  }, [mapLocationId]);

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapboxStyle,
      pitchWithRotate: false,
      dragRotate: false,
      center: [-1.2, 52],
      zoom: 6,
      preserveDrawingBuffer: true // For printing map
    });

    mapRef.current.on("load", () => {
      draw.current = new MapboxDraw({
        displayControlsDefault: false
      });

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        // @ts-ignore
        mapboxgl: mapboxgl
      });

      mapRef.current?.addControl(geocoder, "top-left");
      mapRef.current?.addControl(draw.current, "bottom-right");
      mapRef.current?.addControl(new NavigationControl(), "bottom-right");
      mapRef.current?.resize(); // Fix issue where map is off centre (when it doesn't fill width of page)

      dispatch(setMapLoaded(true));
    });

    mapRef.current?.on("draw.create", (event) => {
      setDrawnFeatureBool(true);

      setTimeout(() => {
        // This causes an error if not in a timeout
        draw.current?.changeMode("direct_select", {
          featureId: event.features[0].id
        });
      }, 0);
    });

    mapRef.current?.on("mousemove", "fields-layer", (event) => {
      // Set state for the feature that is being hovered over
      const { features } = event;
      const { offsetX, offsetY } = event.originalEvent;
      const hoveredFeature = features && features[0];
      if (hoveredFeature) {
        setHoveredFeature(hoveredFeature);
        setHoveredCoordinates({ x: offsetX, y: offsetY });
      }
    });

    mapRef.current?.on("mouseleave", "fields-layer", () => {
      // Clear the hovered feature
      setHoveredFeature(undefined);
      setHoveredCoordinates({ x: 0, y: 0 });
    });

    mapRef.current?.on("dragstart", () => {
      dispatch(setMapLocationId(""));
    });

    mapRef.current?.on("wheel", () => {
      dispatch(setMapLocationId(""));
    });

    mapRef.current?.on("touchstart", () => {
      dragging = false;
    });

    mapRef.current?.on("touchmove", () => {
      dragging = true;
    });
  }, []);

  const onLayerClick = useCallback(
    (event: any) => {
      // Extract the feature from the click event
      const clickedFeature = getFeatureFromMapClick(event);
      if (clickedFeature) {
        // Match the clicked feature to the geojson feature to get accurate coordinates
        const feature = geojson.features.find(
          (feature) =>
            feature.properties?.fieldId === clickedFeature.properties.fieldId
        );

        if (feature) {
          setSelectedSavedFeature(feature);
          setPlantingDialogOpen(true);
        }
      }
    },
    [
      geojson,
      mapPlantingYear,
      fieldsLoading,
      cropsLoading,
      plantingsLoading,
      editorMode
    ]
  );

  const onLayerContextMenu = useCallback(
    (event) => {
      // Get the feature that has been clicked on
      const clickedFeature = getFeatureFromMapClick(event);
      if (clickedFeature) {
        // Find that feature within the geojson to get accurate coordinates
        const filteredFeatures = geojson.features.filter(
          (feature) =>
            feature.properties?.fieldId === clickedFeature.properties.fieldId
        );

        setEditFeature(filteredFeatures[0]);
      }
    },
    [
      geojson,
      mapPlantingYear,
      fieldsLoading,
      cropsLoading,
      plantingsLoading,
      editorMode
    ]
  );

  const onTouchEnd = useCallback(
    (event: any) => {
      if (!dragging) {
        // We want it to be an actual click, not the end of a drag
        onLayerClick(event);
      }
      dragging = false;
    },
    [onLayerClick]
  );

  const setEditFeature = async (feature: Feature) => {
    // Clear plantingYear and set other things on the state
    setSelectedEditFeature(feature);

    // Clear map source so we only see the shape to edit
    clearMapSource();

    await draw.current?.deleteAll();

    // Add the shape to the editor for editing
    draw.current?.add(feature);

    // Change to direct_select so the user can reshape a field
    changeEditorMode("direct_select", {
      featureId: feature.id
    });
  };

  const getFeatureFromMapClick = (event: any) => {
    if (editorMode === "direct_select" || editorMode === "draw_polygon") {
      return null;
    }

    // If any of these are still loading, don't continue because it could cause issues
    if (fieldsLoading || cropsLoading || plantingsLoading) return null;

    const { features } = event;
    if (features && features[0] && features[0].properties.fieldId)
      return features[0];
    return null;
  };

  const clearMapSource = () => {
    // Remove layer and source - important to remove layer before the source
    if (mapRef.current?.getLayer("fields-layer"))
      mapRef.current?.removeLayer("fields-layer");
    if (mapRef.current?.getSource("fields-source"))
      mapRef.current?.removeSource("fields-source");
  };

  const updateMapSource = useCallback(() => {
    if (!mapRef.current) return;
    if (!mapLoaded) return;

    // Set the stops to be able to show the fields in different crop colours
    const cropValues = Object.values(crops);
    const stops = [["", "rgb(255,255,255)"]]; // Add default colour for no planting
    cropValues.forEach((crop) => {
      crop.id && stops.push([crop.id, crop.colour]);
    });

    // Either update source or create it
    const fieldsSource = mapRef.current.getSource("fields-source");
    if (fieldsSource) {
      // @ts-ignore
      fieldsSource.setData(geojson);
    } else {
      // Add the field polygons
      mapRef.current.addSource("fields-source", {
        type: "geojson",
        // @ts-ignore
        data: geojson
      });
    }

    // Always clear and add layer in case crop colours have changed
    if (mapRef.current.getLayer("fields-layer"))
      mapRef.current.removeLayer("fields-layer");

    // Always clear and add layer in case crop colours have changed
    if (mapRef.current.getLayer("fields-layer-outline"))
      mapRef.current.removeLayer("fields-layer-outline");

    // Colour in the fields (either white or planted crop colour)
    mapRef.current.addLayer({
      id: "fields-layer",
      type: "fill",
      source: "fields-source",
      paint: {
        "fill-color": {
          property: "cropId",
          type: "categorical",
          stops: stops
        },
        "fill-outline-color": "black",
        "fill-opacity": 1
      }
    });

    mapRef.current.addLayer({
      id: "fields-layer-outline",
      type: "line",
      source: "fields-source",
      paint: {
        "line-color": "black",
        "line-width": 1
      }
    });
  }, [mapLoaded, crops, geojson, mapPlantingYear]);

  const onEditorClear = async () => {
    // Clear all editor features from map
    await draw.current?.deleteAll();

    // Change back to simple_select mode (default mode)
    changeEditorMode("simple_select");

    setSelectedEditFeature(undefined);
    setDrawnFeatureBool(false);

    dispatch(setMapEditFieldId(""));
  };

  const changeEditorMode = (mode: DrawMode, options?: any) => {
    options = options || {};
    // @ts-ignore TODO: fix
    draw.current?.changeMode(mode, options);

    // Keep track of editorMode ourselves because Mapbox change mode event isn't reliable
    dispatch(setEditorMode(mode));
  };

  const onEditorDrawField = async () => {
    // Clear any features that are currently displayed
    await draw.current?.deleteAll();

    // Change to drawing mode
    changeEditorMode("draw_polygon");

    setSelectedEditFeature(undefined);
  };

  const onEditorSave = async () => {
    const featureCollection = draw.current?.getAll();

    if (featureCollection?.features.length) {
      if (featureCollection.features[0]?.properties?.fieldId) {
        setSavingField(true);
        // Field shape has been edited, so update field with new coordinates
        const size = getSizeOfFeatureInSquareMeters(
          featureCollection.features[0]
        );
        try {
          await dispatch(
            editFieldAction(featureCollection.features[0].properties.fieldId, {
              geometry: featureCollection.features[0].geometry,
              size
            })
          );
          enqueueSnackbar("Field updated successfully", { variant: "success" });
          onEditorClear();
          setSavingField(false);
        } catch (e: any) {
          setSavingField(false);
          handleErrors(e);
        }
      } else {
        // New field to be added
        setDrawnFeature(featureCollection.features[0]);
        setNewFieldDialogOpen(true);
      }
    }
  };

  const onExportMapImage = () => {
    mapRef.current?.getCanvas().toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        setExportImageUrl(url);
      }
    });
  };

  // Listeners only get assigned once, so need to add and remove them
  // with each update to avoid having stale values inside the handlers
  useEffect(() => {
    mapRef.current?.on("click", "fields-layer", onLayerClick);
    mapRef.current?.on("contextmenu", "fields-layer", onLayerContextMenu);
    mapRef.current?.on("style.load", updateMapSource);
    mapRef.current?.on("touchend", "fields-layer", onTouchEnd); // For mobile
    return () => {
      mapRef.current?.off("click", "fields-layer", onLayerClick);
      mapRef.current?.off("contextmenu", "fields-layer", onLayerContextMenu);
      mapRef.current?.off("style.load", updateMapSource);
      mapRef.current?.off("touchend", "fields-layer", onTouchEnd);
    };
  }, [onLayerClick, onLayerContextMenu, updateMapSource, onTouchEnd]);

  useEffect(() => {
    if (mapLoaded && geojson && mapEditFieldId) {
      const feature = geojson.features.find(
        (feature) => feature.properties?.fieldId === mapEditFieldId
      );
      if (feature) setEditFeature(feature);
    }
  }, [mapLoaded, geojson, mapEditFieldId]);

  useEffect(() => {
    if (selectedEditFeature) {
      const coordinates =
        (selectedEditFeature.geometry?.type === "Polygon" &&
          selectedEditFeature.geometry?.coordinates?.[0]) ||
        null;
      if (coordinates) {
        const bounds = coordinates.reduce(
          (bounds, coord) => {
            // @ts-ignore
            return bounds.extend(coord);
          },
          new mapboxgl.LngLatBounds(
            coordinates[0] as LngLatLike,
            coordinates[0] as LngLatLike
          )
        );
        mapRef.current?.fitBounds(bounds, { padding: 150 });
        dispatch(setMapLocationId(""));
      }
    }
  }, [selectedEditFeature]);

  useEffect(() => {
    // Ensure selectedEditFeature isn't set, because in that scenario we don't want the other fields to be drawn
    !selectedEditFeature && updateMapSource();
  }, [selectedEditFeature, updateMapSource]);

  // Set the map location
  useEffect(() => {
    if (
      mapLoaded &&
      !locationsLoading &&
      authenticated &&
      defaultMapLocation &&
      mapLocationId === ""
    ) {
      dispatch(setMapLocationId(defaultMapLocation));
    }
  }, [mapLoaded, locationsLoading, authenticated, defaultMapLocation]);

  return (
    <>
      <MapControlStyleContainer>
        <div
          ref={mapContainerRef}
          style={{
            visibility: mapLoaded ? "visible" : "hidden",
            width: permanentDrawerBool
              ? `calc(100vw - ${drawerWidth}px)`
              : "100vw",
            height: `calc(${getVh(100)} - ${navHeight}px)`
          }}
        />
      </MapControlStyleContainer>

      {/* Button to open drawer */}
      {!permanentDrawerBool && (
        <div
          className={classes.menuIconContainer}
          style={{
            top: extraExtraSmallScreen
              ? navHeight + geocoderOffsetHeight + 25
              : navHeight + 10
          }}>
          <IconButton
            className={classes.menuButton}
            onClick={() => setDrawerOpen(true)}>
            <Menu className={classes.menuIcon} />
          </IconButton>
        </div>
      )}

      {/* Drawer with map related controls */}
      <MuiDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
        variant={permanentDrawerBool ? "permanent" : "temporary"}
        PaperProps={{
          style: {
            border: "none",
            width: drawerWidth,
            top: permanentDrawerBool ? navHeight : 0,
            height: permanentDrawerBool
              ? `calc(${getVh(100)} - ${navHeight}px)`
              : getVh(100)
          }
        }}>
        {mapRef.current && (
          <CropMapDrawer
            plantingListItems={plantingListItems}
            onExportMapImage={onExportMapImage}
            setLocationsDialogOpen={setLocationsDialogOpen}
          />
        )}
      </MuiDrawer>

      {selectedSavedFeature?.properties?.plantingId && (
        <EditPlantingDialog
          open={plantingDialogOpen}
          plantingId={selectedSavedFeature?.properties?.plantingId}
          onClose={() => {
            setPlantingDialogOpen(false);
            setTimeout(() => {
              setSelectedSavedFeature(undefined);
            }, 200);
          }}
        />
      )}

      {!selectedSavedFeature?.properties?.plantingId && (
        <NewPlantingDialog
          open={plantingDialogOpen}
          fieldId={selectedSavedFeature?.properties?.fieldId}
          year={(mapPlantingYear !== "" && mapPlantingYear) || ""}
          onPlantingCreated={(year) => setMapPlantingYear(year)}
          onClose={() => {
            setPlantingDialogOpen(false);
            setTimeout(() => {
              setSelectedSavedFeature(undefined);
            }, 200);
          }}
        />
      )}

      <FieldDialog
        open={newFieldDialogOpen}
        onClose={() => setNewFieldDialogOpen(false)}
        drawnFeature={drawnFeature}
        onFieldAdded={() => {
          onEditorClear();
        }}
      />

      <DeleteFieldDialog
        open={deleteFieldOpen}
        onClose={() => {
          setDeleteFieldOpen(false);
          onEditorClear();
        }}
        fieldId={selectedEditFeature?.properties?.fieldId}
      />

      <LocationsDialog
        open={locationsDialogOpen}
        onClose={() => setLocationsDialogOpen(false)}
        map={mapRef.current}
      />

      <DrawControls
        editorMode={editorMode}
        mapContainerWidth={mapContainerWidth}
        onEditorDrawField={onEditorDrawField}
        onDeleteField={() => setDeleteFieldOpen(true)}
        onEditorClear={onEditorClear}
        onEditorSave={onEditorSave}
        drawnFeatureBool={drawnFeatureBool}
        savingField={savingField}
        selectedEditFeature={selectedEditFeature}
      />

      {mapLoaded && hoveredFeature && (
        <FieldHoverTooltip
          hoveredFeature={hoveredFeature}
          hoveredCoordinates={hoveredCoordinates}
          editorMode={editorMode}
        />
      )}

      <ExportDialog
        plantingListItems={plantingListItems}
        exportImageUrl={exportImageUrl}
        plantingYear={mapPlantingYear}
        onClose={() => setExportImageUrl("")}
      />

      {editorMode === "simple_select" && (
        <CustomButton
          className={classes.helpButton}
          color="primary"
          startIcon={<HelpOutline />}
          onClick={() => dispatch(setWalkthroughRunning(true))}
          size="small"
          tooltipText="Start Help Guide"
          style={{
            top: navHeight + geocoderOffsetHeight + 25
          }}>
          Help
        </CustomButton>
      )}

      <Walkthrough setMapDrawerOpen={setDrawerOpen} />
    </>
  );
};

export default CropMap;
