import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteFieldAction } from "../redux/dataActions";
import { RootState } from "../redux/store";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CustomButton
} from "../ui";
import useHandleErrors from "../useHandleErrors";

interface IDeleteFieldDialog {
  open: boolean;
  onClose: Function;
  fieldId: string;
}

export const DeleteFieldDialog = ({
  open,
  onClose,
  fieldId
}: IDeleteFieldDialog) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();

  const [deleting, setDeleting] = useState(false);

  const { fields } = useSelector((state: RootState) => state.data);

  const field = useMemo(() => fields[fieldId], [fieldId, fields]);

  const onDelete = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteFieldAction(fieldId));
      enqueueSnackbar("Field deleted successfully", {
        variant: "success"
      });
      onClose();
      setDeleting(false);
    } catch (e: any) {
      setDeleting(false);
      handleErrors(e);
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle
        title="Delete Field"
        onClose={() => onClose()}
        loading={deleting}
      />
      <DialogContent>
        <Typography align="center">{`Are you sure you want to delete the field "${field?.name}"?`}</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClick={() => onClose()}>
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => onDelete()}
          disabled={deleting}>
          Confirm
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFieldDialog;
