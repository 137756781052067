import { Typography, capitalize } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const AccountAuditUpdate = ({ newData, oldData }: IAuditLogEntry) => {
  const { locations } = useSelector((state: RootState) => state.data);
  return (
    <>
      <Typography>Updated account settings</Typography>
      <ul style={{ margin: 0 }}>
        {Object.keys(newData).map((key) => {
          if (newData[key] === oldData[key]) return null;
          if (key === "modifiedOn") return null;
          if (key === "accountName") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the account name from "{oldData[key]}" to "
                  {newData[key]}"
                </Typography>
              </li>
            );
          }
          if (key === "email") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the account email from "{oldData[key]}" to "
                  {newData[key]}"
                </Typography>
              </li>
            );
          }
          if (key === "defaultMapLocation") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the default map location from "
                  {locations[oldData[key]]?.name || "Unknown Location"}" to "
                  {locations[newData[key]]?.name || "Unknown Location"}"
                </Typography>
              </li>
            );
          }
          if (key === "defaultMapStyle") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the default map style from {capitalize(oldData[key])}{" "}
                  to {capitalize(newData[key])}
                </Typography>
              </li>
            );
          }
          return (
            <li key={key}>
              <Typography variant="body2">Changed the "{key}" value</Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AccountAuditUpdate;
