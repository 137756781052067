import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Grid
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import { ICrop } from "../../types";

const useStyles = makeStyles((theme) => ({
  cropContainer: {
    borderRadius: 10,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    display: "flex"
  },
  cropNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 5px 5px 20px",
    overflow: "hidden",
    wordBreak: "break-word"
  },
  cropColourBar: {
    width: 20,
    borderRadius: "9px 0px 0px 9px"
  }
}));

interface ICropProps {
  crop: ICrop;
  onEdit: Function;
}

export const Crop = ({ crop, onEdit }: ICropProps) => {
  const classes = useStyles({ colour: crop.colour });
  return (
    <div className={classes.cropContainer} data-testid={`crop-${crop.id}`}>
      <div
        data-testid={`crop-colour-bar-${crop.id}`}
        className={classes.cropColourBar}
        style={{ backgroundColor: crop.colour }}
      />
      <Grid container className={classes.cropNameContainer}>
        <Grid item xs>
          <Typography data-testid={`crop-name-${crop.id}`}>
            {crop.name}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            data-testid={`crop-edit-name-btn-${crop.id}`}
            onClick={() => onEdit(crop.id)}>
            <Tooltip
              title={<Typography variant="body2">Edit</Typography>}
              placement="top">
              <Edit />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Crop;
