import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogProps as MuiDialogProps,
  DialogTitleProps as MuiDialogTitleProps,
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  Typography,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  Tooltip,
  LinearProgress
} from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px 5px 10px"
  },
  titleText: { textAlign: "center", flexGrow: 1 },
  titleMenuButton: {
    marginRight: 10
  },
  content: {
    paddingTop: 20,
    paddingBottom: 20
  }
});

interface IDialogTitleProps extends MuiDialogTitleProps {
  title: string;
  onClose?: Function;
  showMenuIcon?: boolean;
  onMenuOpen?: Function;
  loading?: boolean;
}

export const Dialog = ({ fullScreen, ...props }: MuiDialogProps) => {
  const theme = useTheme();
  const extraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <MuiDialog
      fullScreen={fullScreen || extraSmallScreen}
      fullWidth
      {...props}
    />
  );
};

export const DialogTitle = ({
  id,
  title,
  onClose,
  showMenuIcon,
  onMenuOpen,
  loading = false
}: IDialogTitleProps) => {
  const classes = useStyles();
  return (
    <>
      <LinearProgress
        color="primary"
        style={{ visibility: loading ? "visible" : "hidden" }}
      />
      <MuiDialogTitle
        className={classes.title}
        disableTypography
        id={id}
        data-testid={id}>
        {onClose && (
          <IconButton onClick={() => onClose()}>
            <Tooltip
              title={<Typography variant="body2">Close</Typography>}
              placement="top">
              <Close />
            </Tooltip>
          </IconButton>
        )}
        <Typography variant="h6" className={classes.titleText}>
          {title}
        </Typography>
        <IconButton
          onClick={() => onMenuOpen && onMenuOpen()}
          className={classes.titleMenuButton}
          style={{
            visibility: onMenuOpen && showMenuIcon ? "visible" : "hidden"
          }}>
          <Menu />
        </IconButton>
      </MuiDialogTitle>
    </>
  );
};

export const DialogContent = (props: MuiDialogContentProps) => {
  const classes = useStyles();
  return <MuiDialogContent className={classes.content} {...props} />;
};

export const DialogActions = (props: MuiDialogActionsProps) => (
  <MuiDialogActions {...props} />
);

export default Dialog;
