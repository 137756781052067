import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const ProlongedPlantingAuditDelete = ({ oldData }: IAuditLogEntry) => {
  const { fields, crops } = useSelector((state: RootState) => state.data);
  return (
    <>
      <Typography>Deleted a Prolonged Planting</Typography>
      <ul style={{ margin: 0 }}>
        <li>Field: {fields[oldData.fieldId]?.name || "Unknown Field"}</li>
        <li>Crop: {crops[oldData.cropId]?.name || "Unknown Crop"}</li>
        {oldData.startYear && <li>Start Year: {oldData.startYear}</li>}
        {oldData.endYear && <li>End Year: {oldData.endYear}</li>}
      </ul>
    </>
  );
};

export default ProlongedPlantingAuditDelete;
