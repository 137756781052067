import { useEffect, useRef, CSSProperties } from "react";

interface IFieldCanvasProps {
  coordinates: number[][];
  width: number;
  height: number;
  fillStyle?: string;
  strokeStyle?: string;
  canvasStyle?: CSSProperties;
}

const FieldCanvas = ({
  coordinates = [],
  width,
  height,
  strokeStyle = "#cccccc",
  fillStyle = "#ffffff",
  canvasStyle = {}
}: IFieldCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!coordinates.length) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let minX = 0;
    let minY = 0;
    let maxX = 0;
    let maxY = 0;
    coordinates.forEach((p, i) => {
      if (i === 0) {
        minX = maxX = p[0];
        minY = maxY = p[1];
      } else {
        minX = Math.min(p[0], minX);
        minY = Math.min(p[1], minY);
        maxX = Math.max(p[0], maxX);
        maxY = Math.max(p[1], maxY);
      }
    });

    const mapWidth = maxX - minX;
    const mapHeight = maxY - minY;
    const mapCenterX = (maxX + minX) / 2;
    const mapCenterY = (maxY + minY) / 2;

    ctx.beginPath();
    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);
    coordinates.forEach((p) => {
      ctx.lineTo(
        (p[0] - mapCenterX) * scale + canvas.width / 2,
        (p[1] - mapCenterY) * scale + canvas.height / 2
      );
    });

    ctx.lineWidth = 1;
    ctx.strokeStyle = strokeStyle;
    ctx.fillStyle = fillStyle;
    ctx.fill();
    ctx.stroke();

    return () => {
      ctx?.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [coordinates]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{
        transform: "scale(-1,1) rotate(180deg)",
        ...canvasStyle
      }}
    />
  );
};

export default FieldCanvas;
