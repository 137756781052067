import {
  FormControl,
  Grid,
  makeStyles,
  Typography,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { Map } from "mapbox-gl";
import { useSnackbar } from "notistack";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editAccountAction } from "../../redux/accountActions";
import { editLocationAction } from "../../redux/dataActions";
import { RootState } from "../../redux/store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../ui";
import NewLocationDialog from "./NewLocationDialog";
import { ILocation } from "../../types";
import useHandleErrors from "../../useHandleErrors";
import Location from "./Location";
import Loading from "../Loading";
import DeleteLocationDialog from "./DeleteLocationDialog";

const addLocationText = "Add Location";

const useStyles = makeStyles({
  noLocations: {
    display: "flex",
    justifyContent: "center"
  }
});

interface ILocationsDialogProps {
  open: boolean;
  onClose: Function;
  map: Map | undefined;
}

export const LocationsDialog = ({
  open,
  onClose,
  map
}: ILocationsDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();

  const [locationToDelete, setLocationToDelete] = useState<ILocation | null>(
    null
  );
  const [newLocationDialogOpen, setNewLocationDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { locations, locationsLoading } = useSelector(
    (state: RootState) => state.data
  );
  const { defaultMapLocation } = useSelector(
    (state: RootState) => state.account
  );
  const locationValues = useMemo(() => Object.values(locations), [locations]);

  const onSave = async (id: string, name: string) => {
    try {
      setSaving(true);
      await dispatch(editLocationAction(id, { name }));
      enqueueSnackbar("Location updated successfully", { variant: "success" });
      setLocationToDelete(null);
      setSaving(false);
    } catch (e: any) {
      setSaving(false);
      handleErrors(e);
    }
  };

  const onChangeDefault = async (locationId: string) => {
    try {
      await dispatch(editAccountAction({ defaultMapLocation: locationId }));
      enqueueSnackbar("Default location changed successfully", {
        variant: "success"
      });
    } catch (e: any) {
      handleErrors(e);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        <DialogTitle
          title="Locations"
          onClose={() => onClose()}
          loading={saving}
        />
        <DialogContent>
          {locationsLoading ? (
            <Loading />
          ) : locationValues.length > 0 ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="defaultLocationLabel">
                    Default Location
                  </InputLabel>
                  <Select
                    label="Default Location"
                    labelId="defaultLocationLabel"
                    value={defaultMapLocation}
                    onChange={(e) => onChangeDefault(e.target.value as string)}
                    data-testid="default-location-select">
                    {locationValues.map((location) => (
                      <MenuItem
                        key={location.id}
                        value={`${location.id}`}
                        data-testid={`default-location-menu-item-${location.id}`}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {locationValues.map((location) => (
                    <Grid item key={location.id} xs={12}>
                      <Location
                        location={location}
                        onDelete={() => setLocationToDelete(location)}
                        onSave={onSave}
                        saving={saving}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.noLocations}>
              <Typography>
                You don't have any locations, click the "{addLocationText}"
                button below
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="primary"
            onClick={() => setNewLocationDialogOpen(true)}
            disabled={locationsLoading}>
            {addLocationText}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <NewLocationDialog
        open={newLocationDialogOpen}
        onClose={() => setNewLocationDialogOpen(false)}
        map={map}
      />

      {locationToDelete && (
        <DeleteLocationDialog
          open={!!locationToDelete}
          onClose={() => setLocationToDelete(null)}
          location={locationToDelete}
        />
      )}
    </>
  );
};

export default LocationsDialog;
