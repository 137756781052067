import { TextField, Typography, Grid, Link } from "@material-ui/core";
import { sendEmailVerification } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { firebaseSignup } from "../firebaseAuth";
import { createAccountAction } from "../redux/accountActions";
import { CustomButton } from "../ui";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email must not be empty"),
    accountName: yup.string().required("Account Name must not be empty"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters long")
      .required("Password must not be empty"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "The passwords must match")
      .required("Confirm Password must not be empty")
  })
  .required();

export const CreateAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [signingUp, setSigningUp] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      accountName: "",
      password: "",
      confirmPassword: ""
    },
    resolver: yupResolver(schema)
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={3}
      style={{ maxWidth: 300, margin: "auto", textAlign: "center" }}>
      <Grid item xs={12} style={{ margin: "auto" }}>
        <img
          src="../logos/main.svg"
          alt="main-logo"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="accountName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Account Name"
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Password"
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Confirm Password"
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <CustomButton
          color="primary"
          disabled={signingUp}
          onClick={handleSubmit(async ({ email, password, accountName }) => {
            setSigningUp(true);
            try {
              const resp = await firebaseSignup(email, password);
              if (resp?.user) {
                await sendEmailVerification(resp.user);
                dispatch(
                  createAccountAction({
                    accountId: resp.user.uid,
                    accountName,
                    email
                  })
                );
                enqueueSnackbar(
                  "Verification email sent, please check your inbox and junk/spam folder",
                  {
                    variant: "success"
                  }
                );
              } else {
                enqueueSnackbar("Something went wrong, please try again", {
                  variant: "error"
                });
              }
            } catch (e: any) {
              setSigningUp(false);
              if (e.code === "auth/email-already-in-use") {
                enqueueSnackbar("An account already exists with that email", {
                  variant: "error"
                });
              }
            }
          })}>
          Create Account
        </CustomButton>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Already have an account?{" "}
          <Link
            color="primary"
            onClick={() => history.push("/login")}
            style={{ cursor: "pointer" }}>
            Login
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CreateAccount;
