import { Grid, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { colours } from "../../theme";
import { CustomButton } from "../../ui";
import { ILocation } from "../../types";

interface ILocationProps {
  location: ILocation;
  onDelete: () => void;
  onSave: (id: string, name: string) => void;
  saving: boolean;
}

export const Location = ({
  location,
  onDelete,
  onSave,
  saving
}: ILocationProps) => {
  const [value, setValue] = useState(location.name);
  const { defaultMapLocation } = useSelector(
    (state: RootState) => state.account
  );

  useEffect(() => {
    setValue(location.name);
  }, [location]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      data-testid={`location-${location.id}`}>
      <Grid item>
        <CustomButton
          tooltipText="Delete Location"
          variant="outlined"
          onClick={() => onDelete()}
          disabled={location.id === defaultMapLocation || saving}
          data-testid="location-delete-btn">
          <Delete style={{ color: colours.red }} />
        </CustomButton>
      </Grid>
      <Grid item xs>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={value}
          onChange={(e) =>
            e.target.value.length <= 40 && setValue(e.target.value)
          }
          disabled={saving}
          inputProps={{ "data-testid": "location-name-input" }}
        />
      </Grid>
      <Grid item>
        <CustomButton
          color="primary"
          disabled={!value || value === location.name || saving}
          onClick={() => location?.id && onSave(location.id, value)}
          data-testid="location-save-btn">
          Save
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default Location;
