import { Grid, Typography } from "@material-ui/core";

import { addPlantingText } from "./Plantings";

const InfoContent = () => (
  <Grid
    container
    spacing={1}
    justifyContent="center"
    style={{ textAlign: "center" }}>
    <Grid item>
      <Typography>
        On this page you can see a list of your Plantings. To get started select
        a year or field from the filters.
      </Typography>
    </Grid>
    <Grid item>
      <Typography>
        To add plantings, press the "{addPlantingText}" button below or click
        directly on the field on the map. To create a planting you must have
        created at least one field and crop.
      </Typography>
    </Grid>
  </Grid>
);

export default InfoContent;
