import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { RootState } from "../redux/store";

export const UnauthenticatedRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const { authenticated } = useSelector((state: RootState) => state.account);
  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-ignore
        authenticated === true ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export default UnauthenticatedRoute;
