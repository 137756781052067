import { Typography, Grid } from "@material-ui/core";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../redux/store";
import { CustomButton } from "../ui";

export const VerifyEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [clicked, setClicked] = useState(false);
  const { email } = useSelector((state: RootState) => state.account);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={3}
      style={{ maxWidth: 300, margin: "auto", textAlign: "center" }}>
      <Grid item xs={12} style={{ width: "80%", margin: "auto" }}>
        <img
          src="../logos/main.svg"
          alt="main-logo"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Email Verification</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          An email has been sent to {email}, please find it and click the link
          to verify your account. If you can't find the email, please check your
          spam/junk folder. If you still can't find it, press "Resend" below to
          send another.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          color="primary"
          disabled={clicked}
          onClick={async () => {
            setClicked(true);
            try {
              const user = getAuth().currentUser;
              if (user) {
                await sendEmailVerification(user);
                enqueueSnackbar(
                  "Verification email sent, please check your inbox and junk/spam folder",
                  {
                    variant: "success"
                  }
                );
              } else {
                enqueueSnackbar("Something went wrong, please try again", {
                  variant: "error"
                });
              }
            } catch (err) {
              console.error(err);
              enqueueSnackbar("Something went wrong, please try again", {
                variant: "error"
              });
            }
          }}>
          Resend
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Once you've clicked on that link, you can refresh this page to access
          your account.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
