import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Tooltip
} from "@material-ui/core";
import { Edit, BorderColor } from "@material-ui/icons";

import { convertSquareMetersToAcres } from "../../helpers";
import FieldCanvas from "./FieldCanvas";
import { IField } from "../../types";

interface IFieldProps {
  field: IField;
  onEditName: (fieldId: string) => void;
  onEditShape: (fieldId: string) => void;
}

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    borderRadius: 10,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  },
  fieldNameContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 20px",
    overflow: "hidden",
    wordBreak: "break-word"
  },
  fieldCanvasContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 10
  }
}));

const Field = ({ field, onEditName, onEditShape }: IFieldProps) => {
  const classes = useStyles();
  return (
    <div className={classes.fieldContainer} data-testid={`field-${field.id}`}>
      <div className={classes.fieldNameContainer}>
        <div>
          <Typography data-testid={`field-name-${field.id}`}>
            {field.name}
          </Typography>
          <Typography variant="body2" data-testid={`field-size-${field.id}`}>
            {convertSquareMetersToAcres(field.size)} acres
          </Typography>
        </div>
        <Grid item xs />
        <IconButton
          onClick={() => field?.id && onEditShape(field.id)}
          data-testid={`field-edit-shape-btn-${field.id}`}>
          <Tooltip
            title={<Typography variant="body2">Edit Shape</Typography>}
            placement="top">
            <BorderColor />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={() => field?.id && onEditName(field.id)}
          data-testid={`field-edit-name-btn-${field.id}`}>
          <Tooltip
            title={<Typography variant="body2">Edit Name</Typography>}
            placement="top">
            <Edit />
          </Tooltip>
        </IconButton>
      </div>
      <div className={classes.fieldCanvasContainer}>
        <FieldCanvas
          coordinates={JSON.parse(field.feature)["geometry"]["coordinates"][0]}
          width={150}
          height={100}
        />
      </div>
    </div>
  );
};

export default Field;
