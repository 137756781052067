import {
  Unsubscribe,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { auditCollection } from "./firestore";
import { RootState } from "./redux/store";
import { IAuditLogEntry, TAuditEntityType, TAuditEventType } from "./types";

export const resultsLimit = 100;

export interface IUseAuditLogFilters {
  entityType?: TAuditEntityType;
  eventType?: TAuditEventType;
}

export const useAuditLog = (options?: {
  skip?: boolean;
  filters?: IUseAuditLogFilters;
}) => {
  const {
    account: { accountId, emailVerified }
  } = useSelector((state: RootState) => state);
  const [logs, setLogs] = useState<IAuditLogEntry[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unsubAuditLog: Unsubscribe;
    const constraints = [where("accountId", "==", accountId)];
    if (options?.filters?.entityType)
      constraints.push(where("entityType", "==", options.filters.entityType));
    if (options?.filters?.eventType)
      constraints.push(where("eventType", "==", options.filters.eventType));

    if (!options?.skip && accountId && emailVerified) {
      setLoading(true);
      unsubAuditLog = onSnapshot(
        query(
          auditCollection,
          ...constraints,
          orderBy("updatedAt", "desc"),
          limit(resultsLimit)
        ),
        (snapshot) => {
          setLoading(false);
          setLogs(snapshot.docs.map((doc) => doc.data()));
        }
      );
    }

    return () => unsubAuditLog?.();
  }, [
    accountId,
    emailVerified,
    options?.skip,
    options?.filters?.entityType,
    options?.filters?.eventType
  ]);

  return { logs, loading };
};

export default useAuditLog;
