import { Grid, Typography, TextField } from "@material-ui/core";
import { Feature } from "geojson";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  getSizeOfFeatureInSquareMeters,
  squareMetersToAcres
} from "../../helpers";
import { createFieldAction, editFieldAction } from "../../redux/dataActions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../ui";
import { IField } from "../../types";
import useHandleErrors from "../../useHandleErrors";

interface IFieldDialogProps {
  open: boolean;
  onClose: Function;
  drawnFeature?: Feature;
  onFieldAdded?: Function;
  field?: IField;
}

export const FieldDialog = ({
  open,
  onClose,
  drawnFeature,
  onFieldAdded,
  field
}: IFieldDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(field?.name || "");

  const size = useMemo(
    () =>
      field?.size ||
      (drawnFeature && getSizeOfFeatureInSquareMeters(drawnFeature)),
    [field, drawnFeature]
  );

  useEffect(() => {
    setName(field?.name || "");
  }, [field]);

  const close = () => {
    setName("");
    onClose();
  };

  const onSubmit = async () => {
    setSaving(true);
    if (field?.id) {
      try {
        await dispatch(editFieldAction(field.id, { name }));
        enqueueSnackbar("Field updated successfully", {
          variant: "success"
        });
        setSaving(false);
        close();
      } catch (e: any) {
        setSaving(false);
        handleErrors(e);
      }
    } else if (drawnFeature && size) {
      const newField = {
        name,
        size,
        feature: JSON.stringify(drawnFeature)
      };

      try {
        await dispatch(createFieldAction(newField));
        enqueueSnackbar("Field created successfully", {
          variant: "success"
        });
        setSaving(false);
        onFieldAdded?.();
        close();
      } catch (e: any) {
        setSaving(false);
        handleErrors(e);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle
        onClose={close}
        title={`${field ? "Edit" : "New"} Field`}
        loading={saving}
      />
      <DialogContent>
        <Grid container spacing={3}>
          {size && (
            <Grid item>
              <Typography>{`${squareMetersToAcres(size)} acres`}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              label="Name"
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => {
                if (e.target.value.length <= 40) setName(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClick={() => onClose()}>
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          disabled={saving || name.length === 0 || name === field?.name}
          onClick={() => onSubmit()}>
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default FieldDialog;
