import { DrawMode } from "@mapbox/mapbox-gl-draw";
import { Typography, makeStyles, Divider } from "@material-ui/core";
import { Feature } from "geojson";
import { useSelector } from "react-redux";

import {
  convertSquareMetersToAcres,
  startAndEndYearsToText
} from "../../helpers";
import { RootState } from "../../redux/store";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: 200,
    position: "absolute",
    margin: 8,
    background: theme.palette.background.paper,
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
    borderRadius: 5,
    zIndex: 3
  },
  cropColourBar: {
    height: 10,
    borderRadius: "0px 0px 5px 5px"
  },
  infoContainer: { padding: 5, textAlign: "center" }
}));

interface IFieldHoverTooltipProps {
  hoveredFeature: Feature;
  hoveredCoordinates: {
    x: number;
    y: number;
  };
  editorMode: DrawMode;
}

const FieldHoverTooltip = ({
  hoveredFeature,
  hoveredCoordinates,
  editorMode
}: IFieldHoverTooltipProps) => {
  const classes = useStyles();
  const { fields, crops, prolongedPlantings } = useSelector(
    (state: RootState) => state.data
  );

  const fieldId = hoveredFeature?.properties?.fieldId;
  const cropId = hoveredFeature?.properties?.cropId;
  const year = hoveredFeature?.properties?.year;
  const prolongedPlantingId = hoveredFeature?.properties?.prolongedPlantingId;
  const prolongedPlanting = prolongedPlantings[prolongedPlantingId];
  const prolongedPlantingYearsText =
    prolongedPlanting &&
    startAndEndYearsToText(
      prolongedPlanting?.startYear,
      prolongedPlanting?.endYear
    );
  const field = fieldId && fields[fieldId];
  const crop = cropId && crops[cropId];

  return (
    (hoveredFeature &&
      editorMode !== "direct_select" &&
      editorMode !== "draw_polygon" && (
        <div
          className={classes.tooltip}
          style={{
            left: hoveredCoordinates.x + 10,
            top: hoveredCoordinates.y - 10
          }}>
          <>
            <div className={classes.infoContainer}>
              {field && <Typography>{field.name}</Typography>}
              {field && (
                <Typography variant="body2">
                  {`${convertSquareMetersToAcres(field.size)} acres`}
                </Typography>
              )}
              {crop && (
                <>
                  <Divider style={{ margin: "5px 0px" }} />
                  {<Typography>{crop.name}</Typography>}
                  <Typography variant="body2">
                    {prolongedPlantingYearsText || `Single year (${year})`}
                  </Typography>
                </>
              )}
            </div>
            {cropId && (
              <div
                className={classes.cropColourBar}
                style={{
                  backgroundColor: crop?.colour
                }}
              />
            )}
          </>
        </div>
      )) ||
    null
  );
};

export default FieldHoverTooltip;
