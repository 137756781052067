import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";

const satelliteStyle = "mapbox://styles/nevilldev/ck6sd155w1g2m1invy2qxyt4f";
const monochromeLightStyle =
  "mapbox://styles/nevilldev/cllcquyrp00yk01pd9j9030jb";
const monochromeDarkStyle =
  "mapbox://styles/nevilldev/cllcq8aq800yb01qp956edayy";

export const useMapStyle = () => {
  const {
    ui: { mapStyle, darkMode }
  } = useSelector((state: RootState) => state);

  let mapboxStyle = "";
  switch (mapStyle) {
    case "satellite":
      mapboxStyle = satelliteStyle;
      break;
    case "monochrome":
    default: {
      if (darkMode) mapboxStyle = monochromeDarkStyle;
      else mapboxStyle = monochromeLightStyle;
    }
  }

  return { mapboxStyle };
};

export default useMapStyle;
