import { makeStyles, Typography, Grid } from "@material-ui/core";

import InfoContent from "./InfoContent";

interface INoPlantingsProps {
  filtered: boolean;
}

const useStyles = makeStyles({
  noPlantingsContainer: { display: "flex", justifyContent: "center" }
});

const NoPlantings = ({ filtered }: INoPlantingsProps) => {
  const classes = useStyles();
  return (
    <div className={classes.noPlantingsContainer}>
      {!filtered ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}>
          <Grid item>
            <InfoContent />
          </Grid>
        </Grid>
      ) : (
        <Typography>
          No plantings were found using the current filters.
        </Typography>
      )}
    </div>
  );
};

export default NoPlantings;
