import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import CropColour from "../../CropColour";

export const CropAuditUpdate = ({ newData, oldData }: IAuditLogEntry) => {
  return (
    <>
      <Typography>Updated the Crop "{newData.name}"</Typography>
      <ul style={{ margin: 0 }}>
        {Object.keys(newData).map((key) => {
          if (newData[key] === oldData[key]) return null;
          if (key === "modifiedOn") return null;
          if (key === "name") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the name from "{oldData.name}" to "{newData.name}"
                </Typography>
              </li>
            );
          }
          if (key === "colour") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the colour from <CropColour colour={oldData.colour} />{" "}
                  to <CropColour colour={newData.colour} />
                </Typography>
              </li>
            );
          }
          return (
            <li key={key}>
              <Typography variant="body2">Changed the "{key}" value</Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CropAuditUpdate;
