import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import yearsArray from "../yearsArray";
import { ISharedLayoutProps } from "./shared";

export const MultiYearLayout = (props: ISharedLayoutProps) => {
  const { fieldValues, cropValues } = props;
  const { control } = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          Multi-year prolonged plantings are for when a field is allocated a
          crop over multiple consecutive years, so you don't have to
          repetitively create single year plantings. However, single year
          plantings take priority over these, and they cannot conflict with an
          existing prolonged planting.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="fieldId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={!fieldValues.length}
              error={!!error}>
              <InputLabel id="fieldSelectLabel">Field*</InputLabel>
              <Select
                label="Field*"
                labelId="fieldSelectLabel"
                inputProps={{
                  "data-testid": "new-planting-dialog-field-select"
                }}
                {...field}>
                {fieldValues.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="cropId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={!cropValues.length}
              error={!!error}>
              <InputLabel id="cropSelectLabel">Crop*</InputLabel>
              <Select
                label="Crop*"
                labelId="cropSelectLabel"
                inputProps={{
                  "data-testid": "new-planting-dialog-crop-select"
                }}
                {...field}>
                {cropValues.map((crop) => (
                  <MenuItem
                    key={crop.id}
                    value={crop.id}
                    style={{ borderLeft: `10px solid ${crop.colour}` }}>
                    {crop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="startYear"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={!!error}>
              <InputLabel id="startYearSelectLabel">Start Year*</InputLabel>
              <Select
                label="Start Year*"
                labelId="startYearSelectLabel"
                {...field}>
                {yearsArray.map((year) => (
                  <MenuItem key={year} value={`${year}`}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="endYear"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={!!error}>
              <InputLabel id="endYearSelectLabel">End Year</InputLabel>
              <Select label="End Year" labelId="endYearSelectLabel" {...field}>
                <MenuItem value="">None</MenuItem>
                {yearsArray.map((year) => (
                  <MenuItem key={year} value={`${year}`}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default MultiYearLayout;
