import { Grid, makeStyles, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import { drawerWidth } from "../../../theme";
import { CustomButton } from "../../../ui";
import DrawerLocations from "./DrawerLocations";
import DrawerMapStyle from "./DrawerMapStyle";
import DrawerPlantings from "./DrawerPlantings";
import { IPlantingListItem } from "../../../types";

const useStyles = makeStyles({
  drawerContent: {
    width: drawerWidth,
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0 auto"
  }
});

interface IDrawerProps {
  plantingListItems: IPlantingListItem[];
  onExportMapImage: Function;
  setLocationsDialogOpen: Function;
}

export const CropMapDrawer = ({
  plantingListItems,
  setLocationsDialogOpen,
  onExportMapImage
}: IDrawerProps) => {
  const classes = useStyles();
  const { mapLoaded, editorMode } = useSelector((state: RootState) => state.ui);
  const disableMapExport =
    !mapLoaded ||
    editorMode === "draw_polygon" ||
    editorMode === "direct_select";
  return (
    <Grid container spacing={3} className={classes.drawerContent}>
      <Grid item xs={12}>
        <DrawerPlantings plantingListItems={plantingListItems} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DrawerLocations setLocationsDialogOpen={setLocationsDialogOpen} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DrawerMapStyle />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <CustomButton
            color="primary"
            disabled={disableMapExport}
            onClick={() => onExportMapImage()}>
            Export Map Image
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CropMapDrawer;
