import { TextField, Grid } from "@material-ui/core";
import { FirebaseError } from "firebase/app";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { firebaseSendPasswordResetEmail } from "../firebaseAuth";
import { CustomButton } from "../ui";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email must not be empty")
  })
  .required();

export const ResetPassword = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema)
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={3}
      style={{ maxWidth: 300, margin: "auto", textAlign: "center" }}>
      <Grid item xs={12} style={{ margin: "auto" }}>
        <img
          src="../logos/main.svg"
          alt="main-logo"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              onChange={onChange}
              autoComplete="new-password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <CustomButton
          color="primary"
          onClick={handleSubmit(async ({ email }) => {
            try {
              await firebaseSendPasswordResetEmail(email);
              history.push("/login");
              enqueueSnackbar(
                "Password reset email sent, please check your inbox and junk/spam folder",
                {
                  variant: "success"
                }
              );
            } catch (err) {
              const error = err as FirebaseError;
              if (error.code === "auth/user-not-found") {
                enqueueSnackbar(
                  "We couldn't find an account associated with that email",
                  {
                    variant: "error"
                  }
                );
              } else {
                enqueueSnackbar("An issue occurred sending the reset email", {
                  variant: "error"
                });
              }
            }
          })}>
          Send Password Reset Email
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
