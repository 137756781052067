import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  DocumentData,
  CollectionReference
} from "firebase/firestore";

import {
  IAccount,
  ICrop,
  IPlanting,
  IField,
  ILocation,
  IAuditLogEntry,
  IProlongedPlanting
} from "./types";

initializeApp({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_ID
});

const firestore = getFirestore();

const createCollection = <T = DocumentData>(collectionName: string) =>
  collection(firestore, collectionName) as CollectionReference<T>;

export const accountsCollection = createCollection<IAccount>("accounts");
export const cropsCollection = createCollection<ICrop>("crops");
export const plantingsCollection = createCollection<IPlanting>("plantings");
export const fieldsCollection = createCollection<IField>("fields");
export const locationsCollection = createCollection<ILocation>("locations");
export const auditCollection = createCollection<IAuditLogEntry>("audit");
export const prolongedPlantingsCollection =
  createCollection<IProlongedPlanting>("prolongedPlantings");
