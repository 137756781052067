import { DrawMode } from "@mapbox/mapbox-gl-draw";
import { Tooltip, Typography, IconButton, makeStyles } from "@material-ui/core";
import {
  Delete,
  Save,
  Close,
  LayersClear,
  BorderColor
} from "@material-ui/icons";
import { Feature } from "geojson";

import { colours } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    left: 0,
    bottom: 10,
    position: "absolute"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    zIndex: 2,
    height: 50,
    width: 175,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
    borderRadius: 30,
    border: `1px solid rgba(255, 255, 255, 0.3)`
  }
}));

interface IDrawControlsProps {
  editorMode: DrawMode;
  mapContainerWidth: number;
  onEditorDrawField: () => void;
  onDeleteField: () => void;
  onEditorClear: () => void;
  onEditorSave: () => void;
  drawnFeatureBool: boolean;
  savingField: boolean;
  selectedEditFeature?: Feature;
}

const DrawControls = ({
  editorMode,
  mapContainerWidth,
  onEditorDrawField,
  onDeleteField,
  onEditorClear,
  onEditorSave,
  drawnFeatureBool,
  savingField,
  selectedEditFeature
}: IDrawControlsProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={{ width: mapContainerWidth }}>
      <div className={classes.controls}>
        <Tooltip
          title={
            <Typography variant="body2">
              {editorMode !== "direct_select" ? "Draw Field" : "Delete Field"}
            </Typography>
          }>
          <div>
            {editorMode !== "direct_select" ? (
              <IconButton
                onClick={() => onEditorDrawField()}
                data-testid="draw-controls-draw-field-btn">
                <BorderColor
                  className="tour_step_draw"
                  style={{
                    color:
                      editorMode === "draw_polygon" ? colours.orange : "unset"
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => onDeleteField()}
                data-testid="draw-controls-delete-field-btn">
                <Delete style={{ color: colours.red }} />
              </IconButton>
            )}
          </div>
        </Tooltip>
        <Tooltip
          title={
            <Typography variant="body2">
              {editorMode === "direct_select" ? "Cancel" : "Clear"}
            </Typography>
          }>
          <div>
            {editorMode === "direct_select" ? (
              <IconButton
                onClick={() => onEditorClear()}
                data-testid="draw-controls-cancel-btn">
                <Close />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => onEditorClear()}
                disabled={editorMode !== "draw_polygon"}
                data-testid="draw-controls-clear-btn">
                <LayersClear className="tour_step_clear_draw" />
              </IconButton>
            )}
          </div>
        </Tooltip>
        <Tooltip title={<Typography variant="body2">Save Field</Typography>}>
          <div>
            <IconButton
              onClick={() => onEditorSave()}
              disabled={
                savingField || (!drawnFeatureBool && !selectedEditFeature)
              }
              data-testid="draw-controls-save-btn">
              <Save className="tour_step_save_field" />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DrawControls;
