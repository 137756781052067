import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { makeArrayOfNumbers } from "../../../helpers";
import { RootState } from "../../../redux/store";
import { setMapPlantingYear } from "../../../redux/uiSlice";
import { CustomButton } from "../../../ui";
import CropLegend from "../CropLegend";
import { IPlantingListItem } from "../../../types";

interface IDrawerPlantingsProps {
  plantingListItems: IPlantingListItem[];
}

export const DrawerPlantings = ({
  plantingListItems
}: IDrawerPlantingsProps) => {
  const dispatch = useDispatch();
  const { maxPlantingYear, minPlantingYear } = useSelector(
    (state: RootState) => state.data
  );
  const { editorMode, mapPlantingYear } = useSelector(
    (state: RootState) => state.ui
  );

  const disabledPlantingYearSelect =
    editorMode === "direct_select" ||
    editorMode === "draw_polygon" ||
    !maxPlantingYear ||
    !minPlantingYear;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled={disabledPlantingYearSelect}>
              <InputLabel id="planting-year-label">Planting Year</InputLabel>
              <Select
                label="Planting Year"
                labelId="planting-year-label"
                value={mapPlantingYear}
                onChange={(e) =>
                  dispatch(setMapPlantingYear(e.target.value as string))
                }
                disabled={disabledPlantingYearSelect}>
                {makeArrayOfNumbers(+minPlantingYear, +maxPlantingYear).map(
                  (year) => (
                    <MenuItem key={year} value={"" + year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <CustomButton
              tooltipText="Clear Planting Year"
              color="primary"
              onClick={() => dispatch(setMapPlantingYear(""))}
              disabled={mapPlantingYear === ""}
              style={{ marginLeft: 10 }}>
              <HighlightOff />
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      {mapPlantingYear && (
        <Grid item xs={12}>
          <CropLegend plantingListItems={plantingListItems} />
        </Grid>
      )}
    </Grid>
  );
};

export default DrawerPlantings;
