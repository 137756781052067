import { Grid, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { colours } from "../../theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CustomButton
} from "../../ui";
import useHandleErrors from "../../useHandleErrors";
import { IPlanting } from "../../types";
import { deletePlantingAction } from "../../redux/dataActions";

interface IDeletePlantingDialogProps {
  open: boolean;
  planting: IPlanting;
  onClose: () => void;
  onPlantingDeleted: () => void;
}

export const DeletePlantingDialog = ({
  open,
  planting,
  onClose,
  onPlantingDeleted
}: IDeletePlantingDialogProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleErrors } = useHandleErrors();
  const { fields, crops } = useSelector((state: RootState) => state.data);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    if (planting?.id) {
      setDeleting(true);
      try {
        await dispatch(deletePlantingAction(planting.id));
        enqueueSnackbar("Planting deleted successfully", {
          variant: "success"
        });
        onClose();
        onPlantingDeleted();
        setDeleting(false);
      } catch (e: any) {
        setDeleting(false);
        handleErrors(e);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} data-testid="delete-planting-dialog">
      <DialogTitle
        title="Delete Planting"
        onClose={onClose}
        loading={deleting}
      />

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Are you sure you want to delete this planting?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {planting?.fieldId && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="caption">Field</Typography>
                  <Typography>{fields[planting.fieldId]?.name}</Typography>
                </Grid>
              )}
              {planting?.cropId && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="caption">Crop</Typography>
                  <Typography>{crops[planting.cropId]?.name}</Typography>
                </Grid>
              )}
              {planting?.year && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="caption">Year</Typography>
                  <Typography>{planting.year}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <CustomButton variant="outlined" onClick={onClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="outlined"
          startIcon={<Delete style={{ color: colours.red }} />}
          onClick={handleDelete}
          disabled={deleting}>
          Delete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePlantingDialog;
