import { Typography } from "@material-ui/core";
import { IAuditLogEntry } from "../../../../types";
import { convertSquareMetersToAcres } from "../../../../helpers";

export const FieldAuditUpdate = ({ newData, oldData }: IAuditLogEntry) => {
  return (
    <>
      <Typography>Updated the Field "{newData.name}"</Typography>
      <ul style={{ margin: 0 }}>
        {Object.keys(newData).map((key) => {
          if (newData[key] === oldData[key]) return null;
          if (key === "modifiedOn") return null;
          if (key === "name") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the name from "{oldData.name}" to "{newData.name}"
                </Typography>
              </li>
            );
          }
          if (key === "feature") {
            return (
              <li key={key}>
                <Typography variant="body2">Changed the shape</Typography>
              </li>
            );
          }
          if (key === "size") {
            return (
              <li key={key}>
                <Typography variant="body2">
                  Changed the size from{" "}
                  {convertSquareMetersToAcres(oldData.size)}ac to{" "}
                  {convertSquareMetersToAcres(newData.size)}ac
                </Typography>
              </li>
            );
          }
          return (
            <li key={key}>
              <Typography variant="body2">Changed the "{key}" value</Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default FieldAuditUpdate;
