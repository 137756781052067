import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { extractCropIdsFromPlantingListItems } from "../../helpers";
import { IPlantingListItem } from "../../types";
import { RootState } from "../../redux/store";

const useStyles = makeStyles({
  container: { maxHeight: 500, overflow: "auto" },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderStyle: "solid"
  }
});

interface ICropLegend {
  plantingListItems: IPlantingListItem[];
}

const CropLegend = ({ plantingListItems }: ICropLegend) => {
  const classes = useStyles();
  const { crops } = useSelector((state: RootState) => state.data);
  const cropIdsInCurrentPlantings =
    extractCropIdsFromPlantingListItems(plantingListItems);

  return (
    (cropIdsInCurrentPlantings.length > 0 && (
      <Grid item xs={12} className={classes.container}>
        <Grid container spacing={1}>
          {cropIdsInCurrentPlantings.map((cropId, index) => {
            const left = index % 2 === 0;
            return (
              <Grid item key={cropId} xs={6}>
                <Typography
                  data-testid={`${cropId}-name`}
                  variant="body2"
                  className={classes.text}
                  style={{
                    borderColor: crops[cropId]?.colour,
                    borderWidth: left ? "0 0 0 10px" : "0 10px 0 0",
                    textAlign: left ? "left" : "right",
                    paddingLeft: left ? 5 : 0,
                    paddingRight: left ? 0 : 5
                  }}>
                  {crops[cropId]?.name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    )) || (
      <Grid container justifyContent="center">
        <Typography variant="body2">
          No plantings for the selected year.
        </Typography>
      </Grid>
    )
  );
};

export default CropLegend;
