import { Feature } from "geojson";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { TFields } from "./redux/dataSlice";
import { RootState } from "./redux/store";
import { IPlantingListItem } from "./types";
import useMergePlantings from "./useMergePlantings";

interface IGeojsonProperties {
  renderType: "Polygon";
  fieldId: string;
  cropId: string;
  year: string;
  plantingId?: string;
  prolongedPlantingId?: string;
}

const createGeojsonProperties = (
  fieldId: string,
  planting?: IPlantingListItem
): IGeojsonProperties => {
  return {
    renderType: "Polygon",
    year: planting?.year || "",
    fieldId,
    cropId: planting?.cropId || "",
    plantingId: planting?.plantingId,
    prolongedPlantingId: planting?.prolongedPlantingId
  };
};

export const createBlankFeatureCollection = (fields: TFields) => {
  const features: Feature[] = [];
  Object.values(fields).forEach((field) => {
    const feature = JSON.parse(field.feature);
    feature.id = field.id;
    if (field.id)
      feature.properties = createGeojsonProperties(field.id, undefined);
    features.push(feature);
  });
  return { type: "FeatureCollection", features };
};

export const createPlantingsFeatureCollection = (
  fields: TFields,
  plantingListItems: IPlantingListItem[]
) => {
  const features: Feature[] = [];
  if (plantingListItems.length) {
    Object.values(fields).forEach((field) => {
      const feature = JSON.parse(field.feature);
      feature.id = field.id;

      // Extract the planting record that is associated with the field
      const matchingPlanting = plantingListItems.find(
        (planting) => planting.fieldId === field.id
      );

      // Assign the properties on the feature object for usage on the map
      feature.properties = createGeojsonProperties(field.id!, matchingPlanting);
      features.push(feature);
    });
  }
  return { type: "FeatureCollection", features };
};

export const useGeojson = () => {
  const { mapPlantingYear } = useSelector((state: RootState) => state.ui);
  const { fields } = useSelector((state: RootState) => state.data);
  const mergedPlantings = useMergePlantings({ year: mapPlantingYear });

  // Geojson with blank fields (no planting data)
  const blankGeojson = useMemo(
    () => createBlankFeatureCollection(fields),
    [fields]
  );

  // Geojson with planting data (using currently selected planting year)
  const plantingsGeojson = useMemo(
    () => createPlantingsFeatureCollection(fields, mergedPlantings),
    [fields, mergedPlantings]
  );

  // Return the plantings geojson if it is populated, otherwise return the blank geojson
  return {
    geojson: plantingsGeojson.features?.length
      ? plantingsGeojson
      : blankGeojson,
    plantingListItems: mergedPlantings
  };
};

export default useGeojson;
