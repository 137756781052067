import { useTheme } from "@material-ui/core";

interface ICropColourProps {
  colour: string;
}

export const CropColour = ({ colour }: ICropColourProps) => {
  const theme = useTheme();
  const constrastText = theme.palette.getContrastText(colour);
  return (
    <span
      style={{
        background: colour,
        color: constrastText,
        borderRadius: 5,
        padding: 2
      }}>
      {colour}
    </span>
  );
};

export default CropColour;
