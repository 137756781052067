import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useMemo, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "./redux/store";
import { setDarkMode } from "./redux/uiSlice";

export const colours = {
  lightGreen: "rgb(153,255,153)",
  red: "rgb(237,93,85)",
  paleGreen: "rgb(223,227,213)",
  lightCream: "rgb(243,243,241)",
  blue: "rgb(26,127,251)",
  orange: "rgb(251,150,26)"
};

export const drawerWidth = 300;

export const useAppTheme = () => {
  const { darkMode } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const setDarkModeEnabled = (enabled: boolean) => {
    localStorage.setItem("darkMode", `${enabled}`);
    dispatch(setDarkMode(enabled));
  };

  const appTheme = useMemo(
    () =>
      createTheme({
        palette: {
          type: darkMode ? "dark" : "light",
          success: { main: colours.lightGreen, contrastText: "#000000" },
          error: { main: colours.red, contrastText: "#ffffff" },
          primary: { main: colours.blue, contrastText: "#ffffff" },
          secondary: { main: colours.orange, contrastText: "#ffffff" },
          background: {
            default: darkMode ? "#181818" : "#f8f8f8",
            paper: darkMode ? "#1f1f1f" : "#ffffff"
          }
        },
        typography: {
          fontFamily: "Open Sans",
          button: {
            textTransform: "none"
          }
        },
        overrides: {
          MuiButton: {
            root: {
              textTransform: "none"
            }
          },
          MuiDialogActions: {
            root: {
              borderTop: `1px solid ${
                darkMode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.12)"
              }`
            }
          }
        }
      }),
    [darkMode]
  );

  return { appTheme, darkMode, setDarkModeEnabled };
};

export const ThemeWrapper: FC = ({ children }) => {
  const { appTheme } = useAppTheme();
  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};
