import {
  AppBar,
  Toolbar,
  Typography,
  withStyles,
  useMediaQuery,
  useTheme,
  Drawer,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutAction } from "../redux/accountActions";
import { RootState } from "../redux/store";
import {
  setAuditLogDialogOpen,
  setCropsDialogOpen,
  setFieldsDialogOpen,
  setPlantingsDialogOpen
} from "../redux/uiSlice";
import { useAppTheme } from "../theme";

export const navHeight = 64;

interface NavItem {
  text: string;
  handler: () => void;
  condition: boolean;
}

const NavTypography = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    padding: "0px 15px 0px 15px",
    color: theme.palette.text.primary,
    "&:hover": {
      opacity: 0.5
    },
    textAlign: "center",
    fontWeight: 500
  }
}))(Typography);

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper,
    height: navHeight
  },
  toolbar: {
    paddingLeft: 10,
    paddingRight: 10,
    height: "100%"
  },
  drawerContentContainer: {
    width: 250,
    height: "100vh",
    overflow: "auto"
  },
  drawerItem: {
    padding: 10,
    cursor: "pointer",
    textAlign: "center"
  },
  menuIcon: {
    color: theme.palette.text.primary
  },
  avatar: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9
    }
  },
  iconContainer: { height: 40, paddingLeft: 5, paddingRight: 10 },
  icon: {
    height: "100%"
  }
}));

export const Nav = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { darkMode, setDarkModeEnabled } = useAppTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [avatarEl, setAvatarEl] = useState<HTMLElement | null>(null);

  const { authenticated, accountName, emailVerified } = useSelector(
    (state: RootState) => state.account
  );

  const extraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const navItems: NavItem[] = [
    {
      text: "Fields",
      handler: () => {
        dispatch(setFieldsDialogOpen(true));
      },
      condition: authenticated && emailVerified
    },
    {
      text: "Crops",
      handler: () => {
        dispatch(setCropsDialogOpen(true));
      },
      condition: authenticated && emailVerified
    },
    {
      text: "Plantings",
      handler: () => {
        dispatch(setPlantingsDialogOpen(true));
      },
      condition: authenticated && emailVerified
    },
    {
      text: "Audit Log",
      handler: () => {
        dispatch(setAuditLogDialogOpen(true));
      },
      condition: authenticated
    },
    {
      text: "Login",
      handler: () => {
        history.push("/login");
      },
      condition: !authenticated
    },
    {
      text: "Create Account",
      handler: () => {
        history.push("/create-account");
      },
      condition: !authenticated
    }
  ];

  const itemsToShow = navItems.filter((item) => item.condition);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAvatarEl(null);
  };

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.iconContainer}>
          <img
            className={classes.icon}
            src="../logos/circleLogo.svg"
            alt="main-logo"
          />
        </div>

        {extraSmallScreen && (
          <IconButton onClick={() => setDrawerOpen(true)}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        )}

        {!extraSmallScreen ? (
          <>
            {itemsToShow.map((item, index) => (
              <NavTypography key={index} onClick={() => item.handler()}>
                {item.text}
              </NavTypography>
            ))}
          </>
        ) : (
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}>
            <div className={classes.drawerContentContainer}>
              {itemsToShow.map((item) => (
                <NavTypography
                  key={item.text}
                  className={classes.drawerItem}
                  onClick={() => {
                    item.handler();
                    setDrawerOpen(false);
                  }}>
                  {item.text}
                </NavTypography>
              ))}
            </div>
          </Drawer>
        )}

        <Grid item xs />

        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={darkMode}
              onChange={(event) => setDarkModeEnabled(event.target.checked)}
            />
          }
          label={<Typography color="textPrimary">Dark Mode</Typography>}
        />

        {accountName && (
          <div onClick={(e) => handleAvatarClick(e)}>
            <Avatar
              className={classes.avatar}
              name={accountName}
              size="50"
              round
            />
          </div>
        )}

        <Menu
          anchorEl={avatarEl}
          open={!!avatarEl}
          onClose={handleAvatarMenuClose}>
          <MenuItem
            onClick={async () => {
              handleAvatarMenuClose();
              await dispatch(logoutAction());
              history.push("/login");
            }}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
